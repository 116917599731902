/*!
 * Bootstrap v4.6.1 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */

//* To change Breakpoints
@import "../node_modules/bootstrap/scss/_functions"
@import "../node_modules/bootstrap/scss/_variables"
// @import "../node_modules/bootstrap/scss/mixins/_breakpoints"
$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px)

// Bootstrap only applied on browser class
// $enable-responsive-font-sizes: true

@import '../node_modules/bootstrap/scss/mixins'
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints)
    @if $name == sm
        .responsive-sm
            @content
    @else if $name == md
        .responsive-md
            @content
    @else if $name == lg
        .responsive-lg
            @content
    @else if $name == xl
        .responsive-xl
            @content
    @else
        @content

@import '../node_modules/bootstrap/scss/root'
@import '../node_modules/bootstrap/scss/reboot'
@import '../node_modules/bootstrap/scss/type'
@import '../node_modules/bootstrap/scss/images'
@import '../node_modules/bootstrap/scss/code'
@import '../node_modules/bootstrap/scss/grid'
// @import '../node_modules/bootstrap/scss/tables'
// @import '../node_modules/bootstrap/scss/forms'
@import '../node_modules/bootstrap/scss/buttons'
@import '../node_modules/bootstrap/scss/transitions'
// @import '../node_modules/bootstrap/scss/dropdown'
// @import '../node_modules/bootstrap/scss/button-group'
// @import '../node_modules/bootstrap/scss/input-group'
// @import '../node_modules/bootstrap/scss/custom-forms'
@import '../node_modules/bootstrap/scss/nav'
@import '../node_modules/bootstrap/scss/navbar'
// @import '../node_modules/bootstrap/scss/card'
// @import '../node_modules/bootstrap/scss/breadcrumb'
// @import '../node_modules/bootstrap/scss/pagination'
// @import '../node_modules/bootstrap/scss/badge'
// @import '../node_modules/bootstrap/scss/jumbotron'
// @import '../node_modules/bootstrap/scss/alert'
// @import '../node_modules/bootstrap/scss/progress'
// @import '../node_modules/bootstrap/scss/media'
// @import '../node_modules/bootstrap/scss/list-group'
// @import '../node_modules/bootstrap/scss/close'
// @import '../node_modules/bootstrap/scss/toasts'
// @import '../node_modules/bootstrap/scss/modal'
// @import '../node_modules/bootstrap/scss/tooltip'
// @import '../node_modules/bootstrap/scss/popover'
// @import '../node_modules/bootstrap/scss/carousel'
// @import '../node_modules/bootstrap/scss/spinners'
@import '../node_modules/bootstrap/scss/utilities'
// @import '../node_modules/bootstrap/scss/print'
