.html-container {
  width: 100%;
  height: calc(100% - 80px);
  opacity: 0;
  pointer-events: none;
  z-index: 100000000;
  padding-bottom: 100px;
  transition: opacity .2s;
  position: fixed;
  top: 80px;
  left: 0;
  overflow: auto;
}

.html-container .menu {
  height: 100%;
  flex-direction: column;
  display: flex;
}

.html-container #medias {
  flex-direction: column;
  align-items: center;
  gap: 10px;
  display: flex;
}

.html-container .navbar-nav {
  position: absolute;
}

.html-container section {
  min-height: 100% !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.html-container .container {
  height: 100%;
}

.html-container .h1 {
  margin-bottom: 20px !important;
}

.html-container #summary-container, .html-container #skills-container, .html-container #softwares-container, .html-container #location-container, .html-container #contact-container {
  display: none;
}

.html-container #location-container, .html-container #contact-container {
  flex-direction: row;
  align-items: baseline;
  gap: 10px;
}

.html-container #picture {
  max-width: 300px;
}

.html-container #skills {
  flex-wrap: wrap;
  gap: 10px;
  display: flex;
}

.html-container .skill {
  float: left;
  text-transform: uppercase;
  border: 1px;
  padding: 5px 10px;
  font-size: 15px;
}

.html-container hr {
  border-top: 1px solid gray;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.html-container div[class^="col"] {
  justify-content: start !important;
}

.show {
  opacity: 1;
  pointer-events: visible;
  transition: opacity 1s;
}

.fa-500px {
  color: #0099e5;
}

.fa-adn {
  color: #4a484c;
}

.fa-amazon {
  color: #f90;
}

.fa-android {
  color: #a4c639;
}

.fa-angellist {
  color: #000;
}

.fa-apple {
  color: #979797;
}

.fa-behance, .fa-behance-square {
  color: #1769ff;
}

.fa-bitbucket, .fa-bitbucket-square {
  color: #205081;
}

.fa-bitcoin {
  color: #ee9209;
}

.fa-black-tie {
  color: #222;
}

.fa-btc {
  color: #ee9209;
}

.fa-buysellads {
  color: #c90100;
}

.fa-cc-amex {
  color: #007bc1;
}

.fa-cc-diners-club {
  color: #004a97;
}

.fa-cc-discover {
  color: #f68121;
}

.fa-cc-jcb {
  color: #003a8f;
}

.fa-cc-mastercard {
  color: #0a3a82;
}

.fa-cc-paypal {
  color: #253b80;
}

.fa-cc-stripe {
  color: #00afe1;
}

.fa-cc-visa {
  color: #0157a2;
}

.fa-chrome {
  color: #4587f3;
}

.fa-codepen {
  color: #000;
}

.fa-connectdevelop {
  color: #391448;
}

.fa-contao {
  color: #eb8623;
}

.fa-creative-commons {
  color: #231f20;
}

.fa-css3 {
  color: #1680c0;
}

.fa-dashcube {
  color: #7f7f7f;
}

.fa-delicious {
  color: #39f;
}

.fa-deviantart {
  color: #4e6252;
}

.fa-digg {
  color: #000;
}

.fa-dribbble {
  color: #444;
}

.fa-dropbox {
  color: #007ee5;
}

.fa-drupal {
  color: #0077c0;
}

.fa-empire {
  color: #000;
}

.fa-expeditedssl {
  color: #343433;
}

.fa-facebook, .fa-facebook-official, .fa-facebook-square {
  color: #3b5998;
}

.fa-firefox {
  color: #e66000;
}

.fa-flickr {
  color: #ff0084;
}

.fa-fonticons {
  color: #1c1e29;
}

.fa-forumbee {
  color: #83ad13;
}

.fa-foursquare {
  color: #0072b1;
}

.fa-ge {
  color: #000;
}

.fa-get-pocket {
  color: #d3505a;
}

.fa-gg, .fa-gg-circle {
  color: #000;
}

.fa-git, .fa-git-square, .fa-github, .fa-github-alt, .fa-github-square {
  color: #333;
}

.fa-gittip {
  color: #630;
}

.fa-google {
  color: #4285f4;
}

.fa-google-plus, .fa-google-plus-square {
  color: #dd4b39;
}

.fa-google-wallet {
  color: #4285f4;
}

.fa-hacker-news {
  color: #f60;
}

.fa-houzz {
  color: #7ac142;
}

.fa-html5 {
  color: #e34f26;
}

.fa-instagram {
  color: #3f729b;
}

.fa-internet-explorer {
  color: #1ebbee;
}

.fa-ioxhost {
  color: #faa729;
}

.fa-joomla {
  color: #142849;
}

.fa-jsfiddle {
  color: #4679bd;
}

.fa-lastfm, .fa-lastfm-square {
  color: #c3000d;
}

.fa-leanpub {
  color: #0c0c0c;
}

.fa-linkedin, .fa-linkedin-square {
  color: #0976b4;
}

.fa-linux {
  color: #333;
}

.fa-maxcdn {
  color: #f60;
}

.fa-meanpath {
  color: #538ed7;
}

.fa-medium {
  color: #000;
}

.fa-odnoklassniki, .fa-odnoklassniki-square {
  color: #ed812b;
}

.fa-opencart {
  color: #2ac2ef;
}

.fa-openid {
  color: #f78c40;
}

.fa-opera {
  color: #cc0f16;
}

.fa-optin-monster {
  color: #83c11f;
}

.fa-pagelines {
  color: #000;
}

.fa-paypal {
  color: #253b80;
}

.fa-pied-piper, .fa-pied-piper-alt, .fa-pied-piper-square {
  color: #2f9f46;
}

.fa-pinterest, .fa-pinterest-p, .fa-pinterest-square {
  color: #cc2127;
}

.fa-qq, .fa-ra, .fa-rebel {
  color: #000;
}

.fa-reddit, .fa-reddit-square {
  color: #ff4500;
}

.fa-renren {
  color: #005eac;
}

.fa-safari {
  color: #1b88ca;
}

.fa-sellsy {
  color: #1f78b9;
}

.fa-share-alt, .fa-share-alt-square {
  color: #01bf01;
}

.fa-shirtsinbulk {
  color: #dd3a26;
}

.fa-simplybuilt {
  color: #000;
}

.fa-skyatlas {
  color: #00adbb;
}

.fa-skype {
  color: #00aff0;
}

.fa-slack {
  color: #0f7965;
}

.fa-slideshare {
  color: #e98325;
}

.fa-soundcloud {
  color: #f80;
}

.fa-spotify {
  color: #7ab800;
}

.fa-stack-exchange {
  color: #000;
}

.fa-stack-overflow {
  color: #fe7a15;
}

.fa-steam, .fa-steam-square {
  color: #0b0b0b;
}

.fa-stumbleupon, .fa-stumbleupon-circle {
  color: #eb4924;
}

.fa-tencent-weibo {
  color: #74af2c;
}

.fa-trello {
  color: #256a92;
}

.fa-tripadvisor {
  color: #589442;
}

.fa-tumblr, .fa-tumblr-square {
  color: #35465c;
}

.fa-twitch {
  color: #6441a5;
}

.fa-twitter, .fa-twitter-square {
  color: #55acee;
}

.fa-viacoin {
  color: #333;
}

.fa-vimeo, .fa-vimeo-square {
  color: #1ab7ea;
}

.fa-vine {
  color: #00b488;
}

.fa-vk {
  color: #45668e;
}

.fa-wechat {
  color: #93d034;
}

.fa-weibo {
  color: #e71d34;
}

.fa-weixin {
  color: #93d034;
}

.fa-wikipedia-w {
  color: #000;
}

.fa-windows {
  color: #00bcf2;
}

.fa-wordpress {
  color: #21759b;
}

.fa-xing, .fa-xing-square {
  color: #026466;
}

.fa-y-combinator, .fa-yc {
  color: #f0652f;
}

.fa-yahoo {
  color: #400191;
}

.fa-yelp {
  color: #af0606;
}

.fa-youtube, .fa-youtube-play, .fa-youtube-square {
  color: #e52d27;
}

.center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.centerX {
  left: 50%;
  transform: translate(-50%);
}

.fullscreen {
  height: 100%;
  width: 100%;
  position: absolute;
}

canvas {
  max-width: 100%;
  height: 100%;
  width: 100%;
  outline: none;
  position: fixed;
  top: 0;
  left: 0;
}

.body {
  box-sizing: border-box;
  margin: 0;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  overflow: hidden;
}

.body :root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

.body *, .body :before, .body :after {
  box-sizing: border-box;
}

.body html {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  font-family: sans-serif;
  line-height: 1.15;
}

.body article, .body aside, .body figcaption, .body figure, .body footer, .body header, .body hgroup, .body main, .body nav, .body section {
  display: block;
}

.body body {
  color: #212529;
  text-align: left;
  background-color: #fff;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}

.body [tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

.body hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

.body h1, .body h2, .body h3, .body h4, .body h5, .body h6 {
  margin-top: 0;
  margin-bottom: .5rem;
}

.body p {
  margin-top: 0;
  margin-bottom: 1rem;
}

.body abbr[title], .body abbr[data-original-title] {
  text-decoration: underline;
  cursor: help;
  text-decoration-skip-ink: none;
  border-bottom: 0;
  text-decoration: underline dotted;
}

.body address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1rem;
}

.body ol, .body ul, .body dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

.body ol ol, .body ul ul, .body ol ul, .body ul ol {
  margin-bottom: 0;
}

.body dt {
  font-weight: 700;
}

.body dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

.body blockquote {
  margin: 0 0 1rem;
}

.body b, .body strong {
  font-weight: bolder;
}

.body small {
  font-size: 80%;
}

.body sub, .body sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

.body sub {
  bottom: -.25em;
}

.body sup {
  top: -.5em;
}

.body a {
  color: #007bff;
  background-color: #0000;
  text-decoration: none;
}

.body a:hover {
  color: #0056b3;
  text-decoration: underline;
}

.body a:not([href]):not([class]), .body a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

.body pre, .body code, .body kbd, .body samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

.body pre {
  -ms-overflow-style: scrollbar;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

.body figure {
  margin: 0 0 1rem;
}

.body img {
  vertical-align: middle;
  border-style: none;
}

.body svg {
  vertical-align: middle;
  overflow: hidden;
}

.body table {
  border-collapse: collapse;
}

.body caption {
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.body th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

.body label {
  margin-bottom: .5rem;
  display: inline-block;
}

.body button {
  border-radius: 0;
}

.body button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

.body input, .body button, .body select, .body optgroup, .body textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}

.body button, .body input {
  overflow: visible;
}

.body button, .body select {
  text-transform: none;
}

.body [role="button"] {
  cursor: pointer;
}

.body select {
  word-wrap: normal;
}

.body button, .body [type="button"], .body [type="reset"], .body [type="submit"] {
  -webkit-appearance: button;
}

.body button:not(:disabled), .body [type="button"]:not(:disabled), .body [type="reset"]:not(:disabled), .body [type="submit"]:not(:disabled) {
  cursor: pointer;
}

.body button::-moz-focus-inner, .body [type="button"]::-moz-focus-inner, .body [type="reset"]::-moz-focus-inner, .body [type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.body input[type="radio"], .body input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

.body textarea {
  resize: vertical;
  overflow: auto;
}

.body fieldset {
  min-width: 0;
  border: 0;
  margin: 0;
  padding: 0;
}

.body legend {
  width: 100%;
  max-width: 100%;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
  margin-bottom: .5rem;
  padding: 0;
  display: block;
}

.body progress {
  vertical-align: baseline;
}

.body [type="number"]::-webkit-inner-spin-button, .body [type="number"]::-webkit-outer-spin-button {
  height: auto;
}

.body [type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

.body [type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

.body ::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

.body output {
  display: inline-block;
}

.body summary {
  cursor: pointer;
  display: list-item;
}

.body template {
  display: none;
}

.body [hidden] {
  display: none !important;
}

.body h1, .body h2, .body h3, .body h4, .body h5, .body h6, .body .h1, .body .h2, .body .h3, .body .h4, .body .h5, .body .h6 {
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.2;
}

.body h1, .body .h1 {
  font-size: 2.5rem;
}

.body h2, .body .h2 {
  font-size: 2rem;
}

.body h3, .body .h3 {
  font-size: 1.75rem;
}

.body h4, .body .h4 {
  font-size: 1.5rem;
}

.body h5, .body .h5 {
  font-size: 1.25rem;
}

.body h6, .body .h6 {
  font-size: 1rem;
}

.body .lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.body .display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2;
}

.body .display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.body .display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.body .display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2;
}

.body hr {
  border: 0;
  border-top: 1px solid #0000001a;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.body small, .body .small {
  font-size: 80%;
  font-weight: 400;
}

.body mark, .body .mark {
  background-color: #fcf8e3;
  padding: .2em;
}

.body .list-unstyled, .body .list-inline {
  padding-left: 0;
  list-style: none;
}

.body .list-inline-item {
  display: inline-block;
}

.body .list-inline-item:not(:last-child) {
  margin-right: .5rem;
}

.body .initialism {
  text-transform: uppercase;
  font-size: 90%;
}

.body .blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.body .blockquote-footer {
  color: #6c757d;
  font-size: 80%;
  display: block;
}

.body .blockquote-footer:before {
  content: "— ";
}

.body .img-fluid {
  max-width: 100%;
  height: auto;
}

.body .img-thumbnail {
  max-width: 100%;
  height: auto;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: .25rem;
  padding: .25rem;
}

.body .figure {
  display: inline-block;
}

.body .figure-img {
  margin-bottom: .5rem;
  line-height: 1;
}

.body .figure-caption {
  color: #6c757d;
  font-size: 90%;
}

.body code {
  color: #e83e8c;
  word-wrap: break-word;
  font-size: 87.5%;
}

a > .body code {
  color: inherit;
}

.body kbd {
  color: #fff;
  background-color: #212529;
  border-radius: .2rem;
  padding: .2rem .4rem;
  font-size: 87.5%;
}

.body kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
}

.body pre {
  color: #212529;
  font-size: 87.5%;
  display: block;
}

.body pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

.body .pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.body .container, .body .container-fluid, .body .container-xl, .body .container-lg, .body .container-md, .body .container-sm {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.body .responsive-sm .container-sm, .body .responsive-sm .container {
  max-width: 540px;
}

.body .responsive-md .container-md, .body .responsive-md .container-sm, .body .responsive-md .container {
  max-width: 720px;
}

.body .responsive-lg .container-lg, .body .responsive-lg .container-md, .body .responsive-lg .container-sm, .body .responsive-lg .container {
  max-width: 960px;
}

.body .responsive-xl .container-xl, .body .responsive-xl .container-lg, .body .responsive-xl .container-md, .body .responsive-xl .container-sm, .body .responsive-xl .container {
  max-width: 1140px;
}

.body .row {
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

.body .no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.body .no-gutters > .col, .body .no-gutters > [class*="col-"] {
  padding-left: 0;
  padding-right: 0;
}

.body .col-xl, .body .col-xl-auto, .body .col-xl-12, .body .col-xl-11, .body .col-xl-10, .body .col-xl-9, .body .col-xl-8, .body .col-xl-7, .body .col-xl-6, .body .col-xl-5, .body .col-xl-4, .body .col-xl-3, .body .col-xl-2, .body .col-xl-1, .body .col-lg, .body .col-lg-auto, .body .col-lg-12, .body .col-lg-11, .body .col-lg-10, .body .col-lg-9, .body .col-lg-8, .body .col-lg-7, .body .col-lg-6, .body .col-lg-5, .body .col-lg-4, .body .col-lg-3, .body .col-lg-2, .body .col-lg-1, .body .col-md, .body .col-md-auto, .body .col-md-12, .body .col-md-11, .body .col-md-10, .body .col-md-9, .body .col-md-8, .body .col-md-7, .body .col-md-6, .body .col-md-5, .body .col-md-4, .body .col-md-3, .body .col-md-2, .body .col-md-1, .body .col-sm, .body .col-sm-auto, .body .col-sm-12, .body .col-sm-11, .body .col-sm-10, .body .col-sm-9, .body .col-sm-8, .body .col-sm-7, .body .col-sm-6, .body .col-sm-5, .body .col-sm-4, .body .col-sm-3, .body .col-sm-2, .body .col-sm-1, .body .col, .body .col-auto, .body .col-12, .body .col-11, .body .col-10, .body .col-9, .body .col-8, .body .col-7, .body .col-6, .body .col-5, .body .col-4, .body .col-3, .body .col-2, .body .col-1 {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.body .col {
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.body .row-cols-1 > * {
  max-width: 100%;
  flex: 0 0 100%;
}

.body .row-cols-2 > * {
  max-width: 50%;
  flex: 0 0 50%;
}

.body .row-cols-3 > * {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.body .row-cols-4 > * {
  max-width: 25%;
  flex: 0 0 25%;
}

.body .row-cols-5 > * {
  max-width: 20%;
  flex: 0 0 20%;
}

.body .row-cols-6 > * {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.body .col-auto {
  width: auto;
  max-width: 100%;
  flex: none;
}

.body .col-1 {
  max-width: 8.33333%;
  flex: 0 0 8.33333%;
}

.body .col-2 {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.body .col-3 {
  max-width: 25%;
  flex: 0 0 25%;
}

.body .col-4 {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.body .col-5 {
  max-width: 41.6667%;
  flex: 0 0 41.6667%;
}

.body .col-6 {
  max-width: 50%;
  flex: 0 0 50%;
}

.body .col-7 {
  max-width: 58.3333%;
  flex: 0 0 58.3333%;
}

.body .col-8 {
  max-width: 66.6667%;
  flex: 0 0 66.6667%;
}

.body .col-9 {
  max-width: 75%;
  flex: 0 0 75%;
}

.body .col-10 {
  max-width: 83.3333%;
  flex: 0 0 83.3333%;
}

.body .col-11 {
  max-width: 91.6667%;
  flex: 0 0 91.6667%;
}

.body .col-12 {
  max-width: 100%;
  flex: 0 0 100%;
}

.body .order-first {
  order: -1;
}

.body .order-last {
  order: 13;
}

.body .order-0 {
  order: 0;
}

.body .order-1 {
  order: 1;
}

.body .order-2 {
  order: 2;
}

.body .order-3 {
  order: 3;
}

.body .order-4 {
  order: 4;
}

.body .order-5 {
  order: 5;
}

.body .order-6 {
  order: 6;
}

.body .order-7 {
  order: 7;
}

.body .order-8 {
  order: 8;
}

.body .order-9 {
  order: 9;
}

.body .order-10 {
  order: 10;
}

.body .order-11 {
  order: 11;
}

.body .order-12 {
  order: 12;
}

.body .offset-1 {
  margin-left: 8.33333%;
}

.body .offset-2 {
  margin-left: 16.6667%;
}

.body .offset-3 {
  margin-left: 25%;
}

.body .offset-4 {
  margin-left: 33.3333%;
}

.body .offset-5 {
  margin-left: 41.6667%;
}

.body .offset-6 {
  margin-left: 50%;
}

.body .offset-7 {
  margin-left: 58.3333%;
}

.body .offset-8 {
  margin-left: 66.6667%;
}

.body .offset-9 {
  margin-left: 75%;
}

.body .offset-10 {
  margin-left: 83.3333%;
}

.body .offset-11 {
  margin-left: 91.6667%;
}

.body .responsive-sm .col-sm {
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.body .responsive-sm .row-cols-sm-1 > * {
  max-width: 100%;
  flex: 0 0 100%;
}

.body .responsive-sm .row-cols-sm-2 > * {
  max-width: 50%;
  flex: 0 0 50%;
}

.body .responsive-sm .row-cols-sm-3 > * {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.body .responsive-sm .row-cols-sm-4 > * {
  max-width: 25%;
  flex: 0 0 25%;
}

.body .responsive-sm .row-cols-sm-5 > * {
  max-width: 20%;
  flex: 0 0 20%;
}

.body .responsive-sm .row-cols-sm-6 > * {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.body .responsive-sm .col-sm-auto {
  width: auto;
  max-width: 100%;
  flex: none;
}

.body .responsive-sm .col-sm-1 {
  max-width: 8.33333%;
  flex: 0 0 8.33333%;
}

.body .responsive-sm .col-sm-2 {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.body .responsive-sm .col-sm-3 {
  max-width: 25%;
  flex: 0 0 25%;
}

.body .responsive-sm .col-sm-4 {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.body .responsive-sm .col-sm-5 {
  max-width: 41.6667%;
  flex: 0 0 41.6667%;
}

.body .responsive-sm .col-sm-6 {
  max-width: 50%;
  flex: 0 0 50%;
}

.body .responsive-sm .col-sm-7 {
  max-width: 58.3333%;
  flex: 0 0 58.3333%;
}

.body .responsive-sm .col-sm-8 {
  max-width: 66.6667%;
  flex: 0 0 66.6667%;
}

.body .responsive-sm .col-sm-9 {
  max-width: 75%;
  flex: 0 0 75%;
}

.body .responsive-sm .col-sm-10 {
  max-width: 83.3333%;
  flex: 0 0 83.3333%;
}

.body .responsive-sm .col-sm-11 {
  max-width: 91.6667%;
  flex: 0 0 91.6667%;
}

.body .responsive-sm .col-sm-12 {
  max-width: 100%;
  flex: 0 0 100%;
}

.body .responsive-sm .order-sm-first {
  order: -1;
}

.body .responsive-sm .order-sm-last {
  order: 13;
}

.body .responsive-sm .order-sm-0 {
  order: 0;
}

.body .responsive-sm .order-sm-1 {
  order: 1;
}

.body .responsive-sm .order-sm-2 {
  order: 2;
}

.body .responsive-sm .order-sm-3 {
  order: 3;
}

.body .responsive-sm .order-sm-4 {
  order: 4;
}

.body .responsive-sm .order-sm-5 {
  order: 5;
}

.body .responsive-sm .order-sm-6 {
  order: 6;
}

.body .responsive-sm .order-sm-7 {
  order: 7;
}

.body .responsive-sm .order-sm-8 {
  order: 8;
}

.body .responsive-sm .order-sm-9 {
  order: 9;
}

.body .responsive-sm .order-sm-10 {
  order: 10;
}

.body .responsive-sm .order-sm-11 {
  order: 11;
}

.body .responsive-sm .order-sm-12 {
  order: 12;
}

.body .responsive-sm .offset-sm-0 {
  margin-left: 0;
}

.body .responsive-sm .offset-sm-1 {
  margin-left: 8.33333%;
}

.body .responsive-sm .offset-sm-2 {
  margin-left: 16.6667%;
}

.body .responsive-sm .offset-sm-3 {
  margin-left: 25%;
}

.body .responsive-sm .offset-sm-4 {
  margin-left: 33.3333%;
}

.body .responsive-sm .offset-sm-5 {
  margin-left: 41.6667%;
}

.body .responsive-sm .offset-sm-6 {
  margin-left: 50%;
}

.body .responsive-sm .offset-sm-7 {
  margin-left: 58.3333%;
}

.body .responsive-sm .offset-sm-8 {
  margin-left: 66.6667%;
}

.body .responsive-sm .offset-sm-9 {
  margin-left: 75%;
}

.body .responsive-sm .offset-sm-10 {
  margin-left: 83.3333%;
}

.body .responsive-sm .offset-sm-11 {
  margin-left: 91.6667%;
}

.body .responsive-md .col-md {
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.body .responsive-md .row-cols-md-1 > * {
  max-width: 100%;
  flex: 0 0 100%;
}

.body .responsive-md .row-cols-md-2 > * {
  max-width: 50%;
  flex: 0 0 50%;
}

.body .responsive-md .row-cols-md-3 > * {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.body .responsive-md .row-cols-md-4 > * {
  max-width: 25%;
  flex: 0 0 25%;
}

.body .responsive-md .row-cols-md-5 > * {
  max-width: 20%;
  flex: 0 0 20%;
}

.body .responsive-md .row-cols-md-6 > * {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.body .responsive-md .col-md-auto {
  width: auto;
  max-width: 100%;
  flex: none;
}

.body .responsive-md .col-md-1 {
  max-width: 8.33333%;
  flex: 0 0 8.33333%;
}

.body .responsive-md .col-md-2 {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.body .responsive-md .col-md-3 {
  max-width: 25%;
  flex: 0 0 25%;
}

.body .responsive-md .col-md-4 {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.body .responsive-md .col-md-5 {
  max-width: 41.6667%;
  flex: 0 0 41.6667%;
}

.body .responsive-md .col-md-6 {
  max-width: 50%;
  flex: 0 0 50%;
}

.body .responsive-md .col-md-7 {
  max-width: 58.3333%;
  flex: 0 0 58.3333%;
}

.body .responsive-md .col-md-8 {
  max-width: 66.6667%;
  flex: 0 0 66.6667%;
}

.body .responsive-md .col-md-9 {
  max-width: 75%;
  flex: 0 0 75%;
}

.body .responsive-md .col-md-10 {
  max-width: 83.3333%;
  flex: 0 0 83.3333%;
}

.body .responsive-md .col-md-11 {
  max-width: 91.6667%;
  flex: 0 0 91.6667%;
}

.body .responsive-md .col-md-12 {
  max-width: 100%;
  flex: 0 0 100%;
}

.body .responsive-md .order-md-first {
  order: -1;
}

.body .responsive-md .order-md-last {
  order: 13;
}

.body .responsive-md .order-md-0 {
  order: 0;
}

.body .responsive-md .order-md-1 {
  order: 1;
}

.body .responsive-md .order-md-2 {
  order: 2;
}

.body .responsive-md .order-md-3 {
  order: 3;
}

.body .responsive-md .order-md-4 {
  order: 4;
}

.body .responsive-md .order-md-5 {
  order: 5;
}

.body .responsive-md .order-md-6 {
  order: 6;
}

.body .responsive-md .order-md-7 {
  order: 7;
}

.body .responsive-md .order-md-8 {
  order: 8;
}

.body .responsive-md .order-md-9 {
  order: 9;
}

.body .responsive-md .order-md-10 {
  order: 10;
}

.body .responsive-md .order-md-11 {
  order: 11;
}

.body .responsive-md .order-md-12 {
  order: 12;
}

.body .responsive-md .offset-md-0 {
  margin-left: 0;
}

.body .responsive-md .offset-md-1 {
  margin-left: 8.33333%;
}

.body .responsive-md .offset-md-2 {
  margin-left: 16.6667%;
}

.body .responsive-md .offset-md-3 {
  margin-left: 25%;
}

.body .responsive-md .offset-md-4 {
  margin-left: 33.3333%;
}

.body .responsive-md .offset-md-5 {
  margin-left: 41.6667%;
}

.body .responsive-md .offset-md-6 {
  margin-left: 50%;
}

.body .responsive-md .offset-md-7 {
  margin-left: 58.3333%;
}

.body .responsive-md .offset-md-8 {
  margin-left: 66.6667%;
}

.body .responsive-md .offset-md-9 {
  margin-left: 75%;
}

.body .responsive-md .offset-md-10 {
  margin-left: 83.3333%;
}

.body .responsive-md .offset-md-11 {
  margin-left: 91.6667%;
}

.body .responsive-lg .col-lg {
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.body .responsive-lg .row-cols-lg-1 > * {
  max-width: 100%;
  flex: 0 0 100%;
}

.body .responsive-lg .row-cols-lg-2 > * {
  max-width: 50%;
  flex: 0 0 50%;
}

.body .responsive-lg .row-cols-lg-3 > * {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.body .responsive-lg .row-cols-lg-4 > * {
  max-width: 25%;
  flex: 0 0 25%;
}

.body .responsive-lg .row-cols-lg-5 > * {
  max-width: 20%;
  flex: 0 0 20%;
}

.body .responsive-lg .row-cols-lg-6 > * {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.body .responsive-lg .col-lg-auto {
  width: auto;
  max-width: 100%;
  flex: none;
}

.body .responsive-lg .col-lg-1 {
  max-width: 8.33333%;
  flex: 0 0 8.33333%;
}

.body .responsive-lg .col-lg-2 {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.body .responsive-lg .col-lg-3 {
  max-width: 25%;
  flex: 0 0 25%;
}

.body .responsive-lg .col-lg-4 {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.body .responsive-lg .col-lg-5 {
  max-width: 41.6667%;
  flex: 0 0 41.6667%;
}

.body .responsive-lg .col-lg-6 {
  max-width: 50%;
  flex: 0 0 50%;
}

.body .responsive-lg .col-lg-7 {
  max-width: 58.3333%;
  flex: 0 0 58.3333%;
}

.body .responsive-lg .col-lg-8 {
  max-width: 66.6667%;
  flex: 0 0 66.6667%;
}

.body .responsive-lg .col-lg-9 {
  max-width: 75%;
  flex: 0 0 75%;
}

.body .responsive-lg .col-lg-10 {
  max-width: 83.3333%;
  flex: 0 0 83.3333%;
}

.body .responsive-lg .col-lg-11 {
  max-width: 91.6667%;
  flex: 0 0 91.6667%;
}

.body .responsive-lg .col-lg-12 {
  max-width: 100%;
  flex: 0 0 100%;
}

.body .responsive-lg .order-lg-first {
  order: -1;
}

.body .responsive-lg .order-lg-last {
  order: 13;
}

.body .responsive-lg .order-lg-0 {
  order: 0;
}

.body .responsive-lg .order-lg-1 {
  order: 1;
}

.body .responsive-lg .order-lg-2 {
  order: 2;
}

.body .responsive-lg .order-lg-3 {
  order: 3;
}

.body .responsive-lg .order-lg-4 {
  order: 4;
}

.body .responsive-lg .order-lg-5 {
  order: 5;
}

.body .responsive-lg .order-lg-6 {
  order: 6;
}

.body .responsive-lg .order-lg-7 {
  order: 7;
}

.body .responsive-lg .order-lg-8 {
  order: 8;
}

.body .responsive-lg .order-lg-9 {
  order: 9;
}

.body .responsive-lg .order-lg-10 {
  order: 10;
}

.body .responsive-lg .order-lg-11 {
  order: 11;
}

.body .responsive-lg .order-lg-12 {
  order: 12;
}

.body .responsive-lg .offset-lg-0 {
  margin-left: 0;
}

.body .responsive-lg .offset-lg-1 {
  margin-left: 8.33333%;
}

.body .responsive-lg .offset-lg-2 {
  margin-left: 16.6667%;
}

.body .responsive-lg .offset-lg-3 {
  margin-left: 25%;
}

.body .responsive-lg .offset-lg-4 {
  margin-left: 33.3333%;
}

.body .responsive-lg .offset-lg-5 {
  margin-left: 41.6667%;
}

.body .responsive-lg .offset-lg-6 {
  margin-left: 50%;
}

.body .responsive-lg .offset-lg-7 {
  margin-left: 58.3333%;
}

.body .responsive-lg .offset-lg-8 {
  margin-left: 66.6667%;
}

.body .responsive-lg .offset-lg-9 {
  margin-left: 75%;
}

.body .responsive-lg .offset-lg-10 {
  margin-left: 83.3333%;
}

.body .responsive-lg .offset-lg-11 {
  margin-left: 91.6667%;
}

.body .responsive-xl .col-xl {
  max-width: 100%;
  flex-grow: 1;
  flex-basis: 0;
}

.body .responsive-xl .row-cols-xl-1 > * {
  max-width: 100%;
  flex: 0 0 100%;
}

.body .responsive-xl .row-cols-xl-2 > * {
  max-width: 50%;
  flex: 0 0 50%;
}

.body .responsive-xl .row-cols-xl-3 > * {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.body .responsive-xl .row-cols-xl-4 > * {
  max-width: 25%;
  flex: 0 0 25%;
}

.body .responsive-xl .row-cols-xl-5 > * {
  max-width: 20%;
  flex: 0 0 20%;
}

.body .responsive-xl .row-cols-xl-6 > * {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.body .responsive-xl .col-xl-auto {
  width: auto;
  max-width: 100%;
  flex: none;
}

.body .responsive-xl .col-xl-1 {
  max-width: 8.33333%;
  flex: 0 0 8.33333%;
}

.body .responsive-xl .col-xl-2 {
  max-width: 16.6667%;
  flex: 0 0 16.6667%;
}

.body .responsive-xl .col-xl-3 {
  max-width: 25%;
  flex: 0 0 25%;
}

.body .responsive-xl .col-xl-4 {
  max-width: 33.3333%;
  flex: 0 0 33.3333%;
}

.body .responsive-xl .col-xl-5 {
  max-width: 41.6667%;
  flex: 0 0 41.6667%;
}

.body .responsive-xl .col-xl-6 {
  max-width: 50%;
  flex: 0 0 50%;
}

.body .responsive-xl .col-xl-7 {
  max-width: 58.3333%;
  flex: 0 0 58.3333%;
}

.body .responsive-xl .col-xl-8 {
  max-width: 66.6667%;
  flex: 0 0 66.6667%;
}

.body .responsive-xl .col-xl-9 {
  max-width: 75%;
  flex: 0 0 75%;
}

.body .responsive-xl .col-xl-10 {
  max-width: 83.3333%;
  flex: 0 0 83.3333%;
}

.body .responsive-xl .col-xl-11 {
  max-width: 91.6667%;
  flex: 0 0 91.6667%;
}

.body .responsive-xl .col-xl-12 {
  max-width: 100%;
  flex: 0 0 100%;
}

.body .responsive-xl .order-xl-first {
  order: -1;
}

.body .responsive-xl .order-xl-last {
  order: 13;
}

.body .responsive-xl .order-xl-0 {
  order: 0;
}

.body .responsive-xl .order-xl-1 {
  order: 1;
}

.body .responsive-xl .order-xl-2 {
  order: 2;
}

.body .responsive-xl .order-xl-3 {
  order: 3;
}

.body .responsive-xl .order-xl-4 {
  order: 4;
}

.body .responsive-xl .order-xl-5 {
  order: 5;
}

.body .responsive-xl .order-xl-6 {
  order: 6;
}

.body .responsive-xl .order-xl-7 {
  order: 7;
}

.body .responsive-xl .order-xl-8 {
  order: 8;
}

.body .responsive-xl .order-xl-9 {
  order: 9;
}

.body .responsive-xl .order-xl-10 {
  order: 10;
}

.body .responsive-xl .order-xl-11 {
  order: 11;
}

.body .responsive-xl .order-xl-12 {
  order: 12;
}

.body .responsive-xl .offset-xl-0 {
  margin-left: 0;
}

.body .responsive-xl .offset-xl-1 {
  margin-left: 8.33333%;
}

.body .responsive-xl .offset-xl-2 {
  margin-left: 16.6667%;
}

.body .responsive-xl .offset-xl-3 {
  margin-left: 25%;
}

.body .responsive-xl .offset-xl-4 {
  margin-left: 33.3333%;
}

.body .responsive-xl .offset-xl-5 {
  margin-left: 41.6667%;
}

.body .responsive-xl .offset-xl-6 {
  margin-left: 50%;
}

.body .responsive-xl .offset-xl-7 {
  margin-left: 58.3333%;
}

.body .responsive-xl .offset-xl-8 {
  margin-left: 66.6667%;
}

.body .responsive-xl .offset-xl-9 {
  margin-left: 75%;
}

.body .responsive-xl .offset-xl-10 {
  margin-left: 83.3333%;
}

.body .responsive-xl .offset-xl-11 {
  margin-left: 91.6667%;
}

.body .btn {
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  display: inline-block;
}

@media (prefers-reduced-motion: reduce) {
  .body .btn {
    transition: none;
  }
}

.body .btn:hover {
  color: #212529;
  text-decoration: none;
}

.body .btn:focus, .body .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 .2rem #007bff40;
}

.body .btn.disabled, .body .btn:disabled {
  opacity: .65;
}

.body .btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.body a.btn.disabled, .body fieldset:disabled a.btn {
  pointer-events: none;
}

.body .btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.body .btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
}

.body .btn-primary:focus, .body .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  border-color: #0062cc;
  box-shadow: 0 0 0 .2rem #268fff80;
}

.body .btn-primary.disabled, .body .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.body .btn-primary:not(:disabled):not(.disabled):active, .body .btn-primary:not(:disabled):not(.disabled).active, .show > .body .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
  border-color: #005cbf;
}

.body .btn-primary:not(:disabled):not(.disabled):active:focus, .body .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .body .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #268fff80;
}

.body .btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.body .btn-secondary:hover {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
}

.body .btn-secondary:focus, .body .btn-secondary.focus {
  color: #fff;
  background-color: #5a6268;
  border-color: #545b62;
  box-shadow: 0 0 0 .2rem #828a9180;
}

.body .btn-secondary.disabled, .body .btn-secondary:disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.body .btn-secondary:not(:disabled):not(.disabled):active, .body .btn-secondary:not(:disabled):not(.disabled).active, .show > .body .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #545b62;
  border-color: #4e555b;
}

.body .btn-secondary:not(:disabled):not(.disabled):active:focus, .body .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .body .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #828a9180;
}

.body .btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.body .btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.body .btn-success:focus, .body .btn-success.focus {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
  box-shadow: 0 0 0 .2rem #48b46180;
}

.body .btn-success.disabled, .body .btn-success:disabled {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.body .btn-success:not(:disabled):not(.disabled):active, .body .btn-success:not(:disabled):not(.disabled).active, .show > .body .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #1e7e34;
  border-color: #1c7430;
}

.body .btn-success:not(:disabled):not(.disabled):active:focus, .body .btn-success:not(:disabled):not(.disabled).active:focus, .show > .body .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #48b46180;
}

.body .btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.body .btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.body .btn-info:focus, .body .btn-info.focus {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
  box-shadow: 0 0 0 .2rem #3ab0c380;
}

.body .btn-info.disabled, .body .btn-info:disabled {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.body .btn-info:not(:disabled):not(.disabled):active, .body .btn-info:not(:disabled):not(.disabled).active, .show > .body .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #117a8b;
  border-color: #10707f;
}

.body .btn-info:not(:disabled):not(.disabled):active:focus, .body .btn-info:not(:disabled):not(.disabled).active:focus, .show > .body .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #3ab0c380;
}

.body .btn-warning {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.body .btn-warning:hover {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
}

.body .btn-warning:focus, .body .btn-warning.focus {
  color: #212529;
  background-color: #e0a800;
  border-color: #d39e00;
  box-shadow: 0 0 0 .2rem #deaa0c80;
}

.body .btn-warning.disabled, .body .btn-warning:disabled {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.body .btn-warning:not(:disabled):not(.disabled):active, .body .btn-warning:not(:disabled):not(.disabled).active, .show > .body .btn-warning.dropdown-toggle {
  color: #212529;
  background-color: #d39e00;
  border-color: #c69500;
}

.body .btn-warning:not(:disabled):not(.disabled):active:focus, .body .btn-warning:not(:disabled):not(.disabled).active:focus, .show > .body .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #deaa0c80;
}

.body .btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.body .btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.body .btn-danger:focus, .body .btn-danger.focus {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
  box-shadow: 0 0 0 .2rem #e1536180;
}

.body .btn-danger.disabled, .body .btn-danger:disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.body .btn-danger:not(:disabled):not(.disabled):active, .body .btn-danger:not(:disabled):not(.disabled).active, .show > .body .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #bd2130;
  border-color: #b21f2d;
}

.body .btn-danger:not(:disabled):not(.disabled):active:focus, .body .btn-danger:not(:disabled):not(.disabled).active:focus, .show > .body .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #e1536180;
}

.body .btn-light {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.body .btn-light:hover {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.body .btn-light:focus, .body .btn-light.focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #dae0e5;
  box-shadow: 0 0 0 .2rem #d8d9db80;
}

.body .btn-light.disabled, .body .btn-light:disabled {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.body .btn-light:not(:disabled):not(.disabled):active, .body .btn-light:not(:disabled):not(.disabled).active, .show > .body .btn-light.dropdown-toggle {
  color: #212529;
  background-color: #dae0e5;
  border-color: #d3d9df;
}

.body .btn-light:not(:disabled):not(.disabled):active:focus, .body .btn-light:not(:disabled):not(.disabled).active:focus, .show > .body .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #d8d9db80;
}

.body .btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.body .btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.body .btn-dark:focus, .body .btn-dark.focus {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
  box-shadow: 0 0 0 .2rem #52585d80;
}

.body .btn-dark.disabled, .body .btn-dark:disabled {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.body .btn-dark:not(:disabled):not(.disabled):active, .body .btn-dark:not(:disabled):not(.disabled).active, .show > .body .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1d2124;
  border-color: #171a1d;
}

.body .btn-dark:not(:disabled):not(.disabled):active:focus, .body .btn-dark:not(:disabled):not(.disabled).active:focus, .show > .body .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #52585d80;
}

.body .btn-outline-primary {
  color: #007bff;
  border-color: #007bff;
}

.body .btn-outline-primary:hover {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.body .btn-outline-primary:focus, .body .btn-outline-primary.focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

.body .btn-outline-primary.disabled, .body .btn-outline-primary:disabled {
  color: #007bff;
  background-color: #0000;
}

.body .btn-outline-primary:not(:disabled):not(.disabled):active, .body .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .body .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.body .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .body .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .body .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #007bff80;
}

.body .btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}

.body .btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.body .btn-outline-secondary:focus, .body .btn-outline-secondary.focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.body .btn-outline-secondary.disabled, .body .btn-outline-secondary:disabled {
  color: #6c757d;
  background-color: #0000;
}

.body .btn-outline-secondary:not(:disabled):not(.disabled):active, .body .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .body .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.body .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .body .btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .show > .body .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #6c757d80;
}

.body .btn-outline-success {
  color: #28a745;
  border-color: #28a745;
}

.body .btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.body .btn-outline-success:focus, .body .btn-outline-success.focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.body .btn-outline-success.disabled, .body .btn-outline-success:disabled {
  color: #28a745;
  background-color: #0000;
}

.body .btn-outline-success:not(:disabled):not(.disabled):active, .body .btn-outline-success:not(:disabled):not(.disabled).active, .show > .body .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.body .btn-outline-success:not(:disabled):not(.disabled):active:focus, .body .btn-outline-success:not(:disabled):not(.disabled).active:focus, .show > .body .btn-outline-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #28a74580;
}

.body .btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8;
}

.body .btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.body .btn-outline-info:focus, .body .btn-outline-info.focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.body .btn-outline-info.disabled, .body .btn-outline-info:disabled {
  color: #17a2b8;
  background-color: #0000;
}

.body .btn-outline-info:not(:disabled):not(.disabled):active, .body .btn-outline-info:not(:disabled):not(.disabled).active, .show > .body .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.body .btn-outline-info:not(:disabled):not(.disabled):active:focus, .body .btn-outline-info:not(:disabled):not(.disabled).active:focus, .show > .body .btn-outline-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #17a2b880;
}

.body .btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}

.body .btn-outline-warning:hover {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.body .btn-outline-warning:focus, .body .btn-outline-warning.focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.body .btn-outline-warning.disabled, .body .btn-outline-warning:disabled {
  color: #ffc107;
  background-color: #0000;
}

.body .btn-outline-warning:not(:disabled):not(.disabled):active, .body .btn-outline-warning:not(:disabled):not(.disabled).active, .show > .body .btn-outline-warning.dropdown-toggle {
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;
}

.body .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .body .btn-outline-warning:not(:disabled):not(.disabled).active:focus, .show > .body .btn-outline-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #ffc10780;
}

.body .btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}

.body .btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.body .btn-outline-danger:focus, .body .btn-outline-danger.focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.body .btn-outline-danger.disabled, .body .btn-outline-danger:disabled {
  color: #dc3545;
  background-color: #0000;
}

.body .btn-outline-danger:not(:disabled):not(.disabled):active, .body .btn-outline-danger:not(:disabled):not(.disabled).active, .show > .body .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.body .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .body .btn-outline-danger:not(:disabled):not(.disabled).active:focus, .show > .body .btn-outline-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #dc354580;
}

.body .btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}

.body .btn-outline-light:hover {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.body .btn-outline-light:focus, .body .btn-outline-light.focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.body .btn-outline-light.disabled, .body .btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: #0000;
}

.body .btn-outline-light:not(:disabled):not(.disabled):active, .body .btn-outline-light:not(:disabled):not(.disabled).active, .show > .body .btn-outline-light.dropdown-toggle {
  color: #212529;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.body .btn-outline-light:not(:disabled):not(.disabled):active:focus, .body .btn-outline-light:not(:disabled):not(.disabled).active:focus, .show > .body .btn-outline-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #f8f9fa80;
}

.body .btn-outline-dark {
  color: #343a40;
  border-color: #343a40;
}

.body .btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.body .btn-outline-dark:focus, .body .btn-outline-dark.focus {
  box-shadow: 0 0 0 .2rem #343a4080;
}

.body .btn-outline-dark.disabled, .body .btn-outline-dark:disabled {
  color: #343a40;
  background-color: #0000;
}

.body .btn-outline-dark:not(:disabled):not(.disabled):active, .body .btn-outline-dark:not(:disabled):not(.disabled).active, .show > .body .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.body .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .body .btn-outline-dark:not(:disabled):not(.disabled).active:focus, .show > .body .btn-outline-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .2rem #343a4080;
}

.body .btn-link {
  color: #007bff;
  font-weight: 400;
  text-decoration: none;
}

.body .btn-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

.body .btn-link:focus, .body .btn-link.focus {
  text-decoration: underline;
}

.body .btn-link:disabled, .body .btn-link.disabled {
  color: #6c757d;
  pointer-events: none;
}

.body .btn-lg {
  border-radius: .3rem;
  padding: .5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.body .btn-sm {
  border-radius: .2rem;
  padding: .25rem .5rem;
  font-size: .875rem;
  line-height: 1.5;
}

.body .btn-block {
  width: 100%;
  display: block;
}

.body .btn-block + .btn-block {
  margin-top: .5rem;
}

.body input[type="submit"].btn-block, .body input[type="reset"].btn-block, .body input[type="button"].btn-block {
  width: 100%;
}

.body .fade {
  transition: opacity .15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .body .fade {
    transition: none;
  }
}

.body .fade:not(.show) {
  opacity: 0;
}

.body .collapse:not(.show) {
  display: none;
}

.body .collapsing {
  height: 0;
  transition: height .35s;
  position: relative;
  overflow: hidden;
}

@media (prefers-reduced-motion: reduce) {
  .body .collapsing {
    transition: none;
  }
}

.body .nav {
  flex-wrap: wrap;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.body .nav-link {
  padding: .5rem 1rem;
  display: block;
}

.body .nav-link:hover, .body .nav-link:focus {
  text-decoration: none;
}

.body .nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.body .nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.body .nav-tabs .nav-item {
  margin-bottom: -1px;
}

.body .nav-tabs .nav-link {
  border: 1px solid #0000;
  border-top-left-radius: .25rem;
  border-top-right-radius: .25rem;
}

.body .nav-tabs .nav-link:hover, .body .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.body .nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: #0000;
  border-color: #0000;
}

.body .nav-tabs .nav-link.active, .body .nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.body .nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}

.body .nav-pills .nav-link {
  border-radius: .25rem;
}

.body .nav-pills .nav-link.active, .body .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.body .nav-fill > .nav-link, .body .nav-fill .nav-item {
  text-align: center;
  flex: auto;
}

.body .nav-justified > .nav-link, .body .nav-justified .nav-item {
  text-align: center;
  flex-grow: 1;
  flex-basis: 0;
}

.body .tab-content > .tab-pane {
  display: none;
}

.body .tab-content > .active {
  display: block;
}

.body .navbar {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: .5rem 1rem;
  display: flex;
  position: relative;
}

.body .navbar .container, .body .navbar .container-fluid, .body .navbar .container-sm, .body .navbar .container-md, .body .navbar .container-lg, .body .navbar .container-xl {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.body .navbar-brand {
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
  margin-right: 1rem;
  padding-top: .3125rem;
  padding-bottom: .3125rem;
  display: inline-block;
}

.body .navbar-brand:hover, .body .navbar-brand:focus {
  text-decoration: none;
}

.body .navbar-nav {
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: flex;
}

.body .navbar-nav .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.body .navbar-nav .dropdown-menu {
  float: none;
  position: static;
}

.body .navbar-text {
  padding-top: .5rem;
  padding-bottom: .5rem;
  display: inline-block;
}

.body .navbar-collapse {
  flex-grow: 1;
  flex-basis: 100%;
  align-items: center;
}

.body .navbar-toggler {
  background-color: #0000;
  border: 1px solid #0000;
  border-radius: .25rem;
  padding: .25rem .75rem;
  font-size: 1.25rem;
  line-height: 1;
}

.body .navbar-toggler:hover, .body .navbar-toggler:focus {
  text-decoration: none;
}

.body .navbar-toggler-icon {
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: center / 100% 100% no-repeat;
  display: inline-block;
}

@media (max-width: 575.98px) {
  .body .navbar-expand-sm > .container, .body .navbar-expand-sm > .container-fluid, .body .navbar-expand-sm > .container-sm, .body .navbar-expand-sm > .container-md, .body .navbar-expand-sm > .container-lg, .body .navbar-expand-sm > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

.body .navbar-expand-sm .responsive-sm {
  flex-flow: row;
  justify-content: flex-start;
}

.body .navbar-expand-sm .responsive-sm .navbar-nav {
  flex-direction: row;
}

.body .navbar-expand-sm .responsive-sm .navbar-nav .dropdown-menu {
  position: absolute;
}

.body .navbar-expand-sm .responsive-sm .navbar-nav .nav-link {
  padding-left: .5rem;
  padding-right: .5rem;
}

.body .navbar-expand-sm .responsive-sm > .container, .body .navbar-expand-sm .responsive-sm > .container-fluid, .body .navbar-expand-sm .responsive-sm > .container-sm, .body .navbar-expand-sm .responsive-sm > .container-md, .body .navbar-expand-sm .responsive-sm > .container-lg, .body .navbar-expand-sm .responsive-sm > .container-xl {
  flex-wrap: nowrap;
}

.body .navbar-expand-sm .responsive-sm .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.body .navbar-expand-sm .responsive-sm .navbar-toggler {
  display: none;
}

@media (max-width: 767.98px) {
  .body .navbar-expand-md > .container, .body .navbar-expand-md > .container-fluid, .body .navbar-expand-md > .container-sm, .body .navbar-expand-md > .container-md, .body .navbar-expand-md > .container-lg, .body .navbar-expand-md > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

.body .navbar-expand-md .responsive-md {
  flex-flow: row;
  justify-content: flex-start;
}

.body .navbar-expand-md .responsive-md .navbar-nav {
  flex-direction: row;
}

.body .navbar-expand-md .responsive-md .navbar-nav .dropdown-menu {
  position: absolute;
}

.body .navbar-expand-md .responsive-md .navbar-nav .nav-link {
  padding-left: .5rem;
  padding-right: .5rem;
}

.body .navbar-expand-md .responsive-md > .container, .body .navbar-expand-md .responsive-md > .container-fluid, .body .navbar-expand-md .responsive-md > .container-sm, .body .navbar-expand-md .responsive-md > .container-md, .body .navbar-expand-md .responsive-md > .container-lg, .body .navbar-expand-md .responsive-md > .container-xl {
  flex-wrap: nowrap;
}

.body .navbar-expand-md .responsive-md .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.body .navbar-expand-md .responsive-md .navbar-toggler {
  display: none;
}

@media (max-width: 991.98px) {
  .body .navbar-expand-lg > .container, .body .navbar-expand-lg > .container-fluid, .body .navbar-expand-lg > .container-sm, .body .navbar-expand-lg > .container-md, .body .navbar-expand-lg > .container-lg, .body .navbar-expand-lg > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

.body .navbar-expand-lg .responsive-lg {
  flex-flow: row;
  justify-content: flex-start;
}

.body .navbar-expand-lg .responsive-lg .navbar-nav {
  flex-direction: row;
}

.body .navbar-expand-lg .responsive-lg .navbar-nav .dropdown-menu {
  position: absolute;
}

.body .navbar-expand-lg .responsive-lg .navbar-nav .nav-link {
  padding-left: .5rem;
  padding-right: .5rem;
}

.body .navbar-expand-lg .responsive-lg > .container, .body .navbar-expand-lg .responsive-lg > .container-fluid, .body .navbar-expand-lg .responsive-lg > .container-sm, .body .navbar-expand-lg .responsive-lg > .container-md, .body .navbar-expand-lg .responsive-lg > .container-lg, .body .navbar-expand-lg .responsive-lg > .container-xl {
  flex-wrap: nowrap;
}

.body .navbar-expand-lg .responsive-lg .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.body .navbar-expand-lg .responsive-lg .navbar-toggler {
  display: none;
}

@media (max-width: 1199.98px) {
  .body .navbar-expand-xl > .container, .body .navbar-expand-xl > .container-fluid, .body .navbar-expand-xl > .container-sm, .body .navbar-expand-xl > .container-md, .body .navbar-expand-xl > .container-lg, .body .navbar-expand-xl > .container-xl {
    padding-left: 0;
    padding-right: 0;
  }
}

.body .navbar-expand-xl .responsive-xl {
  flex-flow: row;
  justify-content: flex-start;
}

.body .navbar-expand-xl .responsive-xl .navbar-nav {
  flex-direction: row;
}

.body .navbar-expand-xl .responsive-xl .navbar-nav .dropdown-menu {
  position: absolute;
}

.body .navbar-expand-xl .responsive-xl .navbar-nav .nav-link {
  padding-left: .5rem;
  padding-right: .5rem;
}

.body .navbar-expand-xl .responsive-xl > .container, .body .navbar-expand-xl .responsive-xl > .container-fluid, .body .navbar-expand-xl .responsive-xl > .container-sm, .body .navbar-expand-xl .responsive-xl > .container-md, .body .navbar-expand-xl .responsive-xl > .container-lg, .body .navbar-expand-xl .responsive-xl > .container-xl {
  flex-wrap: nowrap;
}

.body .navbar-expand-xl .responsive-xl .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.body .navbar-expand-xl .responsive-xl .navbar-toggler {
  display: none;
}

.body .navbar-expand {
  flex-flow: row;
  justify-content: flex-start;
}

.body .navbar-expand > .container, .body .navbar-expand > .container-fluid, .body .navbar-expand > .container-sm, .body .navbar-expand > .container-md, .body .navbar-expand > .container-lg, .body .navbar-expand > .container-xl {
  padding-left: 0;
  padding-right: 0;
}

.body .navbar-expand .navbar-nav {
  flex-direction: row;
}

.body .navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.body .navbar-expand .navbar-nav .nav-link {
  padding-left: .5rem;
  padding-right: .5rem;
}

.body .navbar-expand > .container, .body .navbar-expand > .container-fluid, .body .navbar-expand > .container-sm, .body .navbar-expand > .container-md, .body .navbar-expand > .container-lg, .body .navbar-expand > .container-xl {
  flex-wrap: nowrap;
}

.body .navbar-expand .navbar-collapse {
  flex-basis: auto;
  display: flex !important;
}

.body .navbar-expand .navbar-toggler {
  display: none;
}

.body .navbar-light .navbar-brand, .body .navbar-light .navbar-brand:hover, .body .navbar-light .navbar-brand:focus {
  color: #000000e6;
}

.body .navbar-light .navbar-nav .nav-link {
  color: #00000080;
}

.body .navbar-light .navbar-nav .nav-link:hover, .body .navbar-light .navbar-nav .nav-link:focus {
  color: #000000b3;
}

.body .navbar-light .navbar-nav .nav-link.disabled {
  color: #0000004d;
}

.body .navbar-light .navbar-nav .show > .nav-link, .body .navbar-light .navbar-nav .active > .nav-link, .body .navbar-light .navbar-nav .nav-link.show, .body .navbar-light .navbar-nav .nav-link.active {
  color: #000000e6;
}

.body .navbar-light .navbar-toggler {
  color: #00000080;
  border-color: #0000001a;
}

.body .navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.body .navbar-light .navbar-text {
  color: #00000080;
}

.body .navbar-light .navbar-text a, .body .navbar-light .navbar-text a:hover, .body .navbar-light .navbar-text a:focus {
  color: #000000e6;
}

.body .navbar-dark .navbar-brand, .body .navbar-dark .navbar-brand:hover, .body .navbar-dark .navbar-brand:focus {
  color: #fff;
}

.body .navbar-dark .navbar-nav .nav-link {
  color: #ffffff80;
}

.body .navbar-dark .navbar-nav .nav-link:hover, .body .navbar-dark .navbar-nav .nav-link:focus {
  color: #ffffffbf;
}

.body .navbar-dark .navbar-nav .nav-link.disabled {
  color: #ffffff40;
}

.body .navbar-dark .navbar-nav .show > .nav-link, .body .navbar-dark .navbar-nav .active > .nav-link, .body .navbar-dark .navbar-nav .nav-link.show, .body .navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.body .navbar-dark .navbar-toggler {
  color: #ffffff80;
  border-color: #ffffff1a;
}

.body .navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.body .navbar-dark .navbar-text {
  color: #ffffff80;
}

.body .navbar-dark .navbar-text a, .body .navbar-dark .navbar-text a:hover, .body .navbar-dark .navbar-text a:focus {
  color: #fff;
}

.body .align-baseline {
  vertical-align: baseline !important;
}

.body .align-top {
  vertical-align: top !important;
}

.body .align-middle {
  vertical-align: middle !important;
}

.body .align-bottom {
  vertical-align: bottom !important;
}

.body .align-text-bottom {
  vertical-align: text-bottom !important;
}

.body .align-text-top {
  vertical-align: text-top !important;
}

.body .bg-primary {
  background-color: #007bff !important;
}

.body a.bg-primary:hover, .body a.bg-primary:focus, .body button.bg-primary:hover, .body button.bg-primary:focus {
  background-color: #0062cc !important;
}

.body .bg-secondary {
  background-color: #6c757d !important;
}

.body a.bg-secondary:hover, .body a.bg-secondary:focus, .body button.bg-secondary:hover, .body button.bg-secondary:focus {
  background-color: #545b62 !important;
}

.body .bg-success {
  background-color: #28a745 !important;
}

.body a.bg-success:hover, .body a.bg-success:focus, .body button.bg-success:hover, .body button.bg-success:focus {
  background-color: #1e7e34 !important;
}

.body .bg-info {
  background-color: #17a2b8 !important;
}

.body a.bg-info:hover, .body a.bg-info:focus, .body button.bg-info:hover, .body button.bg-info:focus {
  background-color: #117a8b !important;
}

.body .bg-warning {
  background-color: #ffc107 !important;
}

.body a.bg-warning:hover, .body a.bg-warning:focus, .body button.bg-warning:hover, .body button.bg-warning:focus {
  background-color: #d39e00 !important;
}

.body .bg-danger {
  background-color: #dc3545 !important;
}

.body a.bg-danger:hover, .body a.bg-danger:focus, .body button.bg-danger:hover, .body button.bg-danger:focus {
  background-color: #bd2130 !important;
}

.body .bg-light {
  background-color: #f8f9fa !important;
}

.body a.bg-light:hover, .body a.bg-light:focus, .body button.bg-light:hover, .body button.bg-light:focus {
  background-color: #dae0e5 !important;
}

.body .bg-dark {
  background-color: #343a40 !important;
}

.body a.bg-dark:hover, .body a.bg-dark:focus, .body button.bg-dark:hover, .body button.bg-dark:focus {
  background-color: #1d2124 !important;
}

.body .bg-white {
  background-color: #fff !important;
}

.body .bg-transparent {
  background-color: #0000 !important;
}

.body .border {
  border: 1px solid #dee2e6 !important;
}

.body .border-top {
  border-top: 1px solid #dee2e6 !important;
}

.body .border-right {
  border-right: 1px solid #dee2e6 !important;
}

.body .border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.body .border-left {
  border-left: 1px solid #dee2e6 !important;
}

.body .border-0 {
  border: 0 !important;
}

.body .border-top-0 {
  border-top: 0 !important;
}

.body .border-right-0 {
  border-right: 0 !important;
}

.body .border-bottom-0 {
  border-bottom: 0 !important;
}

.body .border-left-0 {
  border-left: 0 !important;
}

.body .border-primary {
  border-color: #007bff !important;
}

.body .border-secondary {
  border-color: #6c757d !important;
}

.body .border-success {
  border-color: #28a745 !important;
}

.body .border-info {
  border-color: #17a2b8 !important;
}

.body .border-warning {
  border-color: #ffc107 !important;
}

.body .border-danger {
  border-color: #dc3545 !important;
}

.body .border-light {
  border-color: #f8f9fa !important;
}

.body .border-dark {
  border-color: #343a40 !important;
}

.body .border-white {
  border-color: #fff !important;
}

.body .rounded-sm {
  border-radius: .2rem !important;
}

.body .rounded {
  border-radius: .25rem !important;
}

.body .rounded-top {
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.body .rounded-right {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

.body .rounded-bottom {
  border-bottom-right-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.body .rounded-left {
  border-top-left-radius: .25rem !important;
  border-bottom-left-radius: .25rem !important;
}

.body .rounded-lg {
  border-radius: .3rem !important;
}

.body .rounded-circle {
  border-radius: 50% !important;
}

.body .rounded-pill {
  border-radius: 50rem !important;
}

.body .rounded-0 {
  border-radius: 0 !important;
}

.body .clearfix:after {
  clear: both;
  content: "";
  display: block;
}

.body .d-none {
  display: none !important;
}

.body .d-inline {
  display: inline !important;
}

.body .d-inline-block {
  display: inline-block !important;
}

.body .d-block {
  display: block !important;
}

.body .d-table {
  display: table !important;
}

.body .d-table-row {
  display: table-row !important;
}

.body .d-table-cell {
  display: table-cell !important;
}

.body .d-flex {
  display: flex !important;
}

.body .d-inline-flex {
  display: inline-flex !important;
}

.body .responsive-sm .d-sm-none {
  display: none !important;
}

.body .responsive-sm .d-sm-inline {
  display: inline !important;
}

.body .responsive-sm .d-sm-inline-block {
  display: inline-block !important;
}

.body .responsive-sm .d-sm-block {
  display: block !important;
}

.body .responsive-sm .d-sm-table {
  display: table !important;
}

.body .responsive-sm .d-sm-table-row {
  display: table-row !important;
}

.body .responsive-sm .d-sm-table-cell {
  display: table-cell !important;
}

.body .responsive-sm .d-sm-flex {
  display: flex !important;
}

.body .responsive-sm .d-sm-inline-flex {
  display: inline-flex !important;
}

.body .responsive-md .d-md-none {
  display: none !important;
}

.body .responsive-md .d-md-inline {
  display: inline !important;
}

.body .responsive-md .d-md-inline-block {
  display: inline-block !important;
}

.body .responsive-md .d-md-block {
  display: block !important;
}

.body .responsive-md .d-md-table {
  display: table !important;
}

.body .responsive-md .d-md-table-row {
  display: table-row !important;
}

.body .responsive-md .d-md-table-cell {
  display: table-cell !important;
}

.body .responsive-md .d-md-flex {
  display: flex !important;
}

.body .responsive-md .d-md-inline-flex {
  display: inline-flex !important;
}

.body .responsive-lg .d-lg-none {
  display: none !important;
}

.body .responsive-lg .d-lg-inline {
  display: inline !important;
}

.body .responsive-lg .d-lg-inline-block {
  display: inline-block !important;
}

.body .responsive-lg .d-lg-block {
  display: block !important;
}

.body .responsive-lg .d-lg-table {
  display: table !important;
}

.body .responsive-lg .d-lg-table-row {
  display: table-row !important;
}

.body .responsive-lg .d-lg-table-cell {
  display: table-cell !important;
}

.body .responsive-lg .d-lg-flex {
  display: flex !important;
}

.body .responsive-lg .d-lg-inline-flex {
  display: inline-flex !important;
}

.body .responsive-xl .d-xl-none {
  display: none !important;
}

.body .responsive-xl .d-xl-inline {
  display: inline !important;
}

.body .responsive-xl .d-xl-inline-block {
  display: inline-block !important;
}

.body .responsive-xl .d-xl-block {
  display: block !important;
}

.body .responsive-xl .d-xl-table {
  display: table !important;
}

.body .responsive-xl .d-xl-table-row {
  display: table-row !important;
}

.body .responsive-xl .d-xl-table-cell {
  display: table-cell !important;
}

.body .responsive-xl .d-xl-flex {
  display: flex !important;
}

.body .responsive-xl .d-xl-inline-flex {
  display: inline-flex !important;
}

@media print {
  .body .d-print-none {
    display: none !important;
  }

  .body .d-print-inline {
    display: inline !important;
  }

  .body .d-print-inline-block {
    display: inline-block !important;
  }

  .body .d-print-block {
    display: block !important;
  }

  .body .d-print-table {
    display: table !important;
  }

  .body .d-print-table-row {
    display: table-row !important;
  }

  .body .d-print-table-cell {
    display: table-cell !important;
  }

  .body .d-print-flex {
    display: flex !important;
  }

  .body .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.body .embed-responsive {
  width: 100%;
  padding: 0;
  display: block;
  position: relative;
  overflow: hidden;
}

.body .embed-responsive:before {
  content: "";
  display: block;
}

.body .embed-responsive .embed-responsive-item, .body .embed-responsive iframe, .body .embed-responsive embed, .body .embed-responsive object, .body .embed-responsive video {
  width: 100%;
  height: 100%;
  border: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.body .embed-responsive-21by9:before {
  padding-top: 42.8571%;
}

.body .embed-responsive-16by9:before {
  padding-top: 56.25%;
}

.body .embed-responsive-4by3:before {
  padding-top: 75%;
}

.body .embed-responsive-1by1:before {
  padding-top: 100%;
}

.body .flex-row {
  flex-direction: row !important;
}

.body .flex-column {
  flex-direction: column !important;
}

.body .flex-row-reverse {
  flex-direction: row-reverse !important;
}

.body .flex-column-reverse {
  flex-direction: column-reverse !important;
}

.body .flex-wrap {
  flex-wrap: wrap !important;
}

.body .flex-nowrap {
  flex-wrap: nowrap !important;
}

.body .flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.body .flex-fill {
  flex: auto !important;
}

.body .flex-grow-0 {
  flex-grow: 0 !important;
}

.body .flex-grow-1 {
  flex-grow: 1 !important;
}

.body .flex-shrink-0 {
  flex-shrink: 0 !important;
}

.body .flex-shrink-1 {
  flex-shrink: 1 !important;
}

.body .justify-content-start {
  justify-content: flex-start !important;
}

.body .justify-content-end {
  justify-content: flex-end !important;
}

.body .justify-content-center {
  justify-content: center !important;
}

.body .justify-content-between {
  justify-content: space-between !important;
}

.body .justify-content-around {
  justify-content: space-around !important;
}

.body .align-items-start {
  align-items: flex-start !important;
}

.body .align-items-end {
  align-items: flex-end !important;
}

.body .align-items-center {
  align-items: center !important;
}

.body .align-items-baseline {
  align-items: baseline !important;
}

.body .align-items-stretch {
  align-items: stretch !important;
}

.body .align-content-start {
  align-content: flex-start !important;
}

.body .align-content-end {
  align-content: flex-end !important;
}

.body .align-content-center {
  align-content: center !important;
}

.body .align-content-between {
  align-content: space-between !important;
}

.body .align-content-around {
  align-content: space-around !important;
}

.body .align-content-stretch {
  align-content: stretch !important;
}

.body .align-self-auto {
  align-self: auto !important;
}

.body .align-self-start {
  align-self: flex-start !important;
}

.body .align-self-end {
  align-self: flex-end !important;
}

.body .align-self-center {
  align-self: center !important;
}

.body .align-self-baseline {
  align-self: baseline !important;
}

.body .align-self-stretch {
  align-self: stretch !important;
}

.body .responsive-sm .flex-sm-row {
  flex-direction: row !important;
}

.body .responsive-sm .flex-sm-column {
  flex-direction: column !important;
}

.body .responsive-sm .flex-sm-row-reverse {
  flex-direction: row-reverse !important;
}

.body .responsive-sm .flex-sm-column-reverse {
  flex-direction: column-reverse !important;
}

.body .responsive-sm .flex-sm-wrap {
  flex-wrap: wrap !important;
}

.body .responsive-sm .flex-sm-nowrap {
  flex-wrap: nowrap !important;
}

.body .responsive-sm .flex-sm-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.body .responsive-sm .flex-sm-fill {
  flex: auto !important;
}

.body .responsive-sm .flex-sm-grow-0 {
  flex-grow: 0 !important;
}

.body .responsive-sm .flex-sm-grow-1 {
  flex-grow: 1 !important;
}

.body .responsive-sm .flex-sm-shrink-0 {
  flex-shrink: 0 !important;
}

.body .responsive-sm .flex-sm-shrink-1 {
  flex-shrink: 1 !important;
}

.body .responsive-sm .justify-content-sm-start {
  justify-content: flex-start !important;
}

.body .responsive-sm .justify-content-sm-end {
  justify-content: flex-end !important;
}

.body .responsive-sm .justify-content-sm-center {
  justify-content: center !important;
}

.body .responsive-sm .justify-content-sm-between {
  justify-content: space-between !important;
}

.body .responsive-sm .justify-content-sm-around {
  justify-content: space-around !important;
}

.body .responsive-sm .align-items-sm-start {
  align-items: flex-start !important;
}

.body .responsive-sm .align-items-sm-end {
  align-items: flex-end !important;
}

.body .responsive-sm .align-items-sm-center {
  align-items: center !important;
}

.body .responsive-sm .align-items-sm-baseline {
  align-items: baseline !important;
}

.body .responsive-sm .align-items-sm-stretch {
  align-items: stretch !important;
}

.body .responsive-sm .align-content-sm-start {
  align-content: flex-start !important;
}

.body .responsive-sm .align-content-sm-end {
  align-content: flex-end !important;
}

.body .responsive-sm .align-content-sm-center {
  align-content: center !important;
}

.body .responsive-sm .align-content-sm-between {
  align-content: space-between !important;
}

.body .responsive-sm .align-content-sm-around {
  align-content: space-around !important;
}

.body .responsive-sm .align-content-sm-stretch {
  align-content: stretch !important;
}

.body .responsive-sm .align-self-sm-auto {
  align-self: auto !important;
}

.body .responsive-sm .align-self-sm-start {
  align-self: flex-start !important;
}

.body .responsive-sm .align-self-sm-end {
  align-self: flex-end !important;
}

.body .responsive-sm .align-self-sm-center {
  align-self: center !important;
}

.body .responsive-sm .align-self-sm-baseline {
  align-self: baseline !important;
}

.body .responsive-sm .align-self-sm-stretch {
  align-self: stretch !important;
}

.body .responsive-md .flex-md-row {
  flex-direction: row !important;
}

.body .responsive-md .flex-md-column {
  flex-direction: column !important;
}

.body .responsive-md .flex-md-row-reverse {
  flex-direction: row-reverse !important;
}

.body .responsive-md .flex-md-column-reverse {
  flex-direction: column-reverse !important;
}

.body .responsive-md .flex-md-wrap {
  flex-wrap: wrap !important;
}

.body .responsive-md .flex-md-nowrap {
  flex-wrap: nowrap !important;
}

.body .responsive-md .flex-md-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.body .responsive-md .flex-md-fill {
  flex: auto !important;
}

.body .responsive-md .flex-md-grow-0 {
  flex-grow: 0 !important;
}

.body .responsive-md .flex-md-grow-1 {
  flex-grow: 1 !important;
}

.body .responsive-md .flex-md-shrink-0 {
  flex-shrink: 0 !important;
}

.body .responsive-md .flex-md-shrink-1 {
  flex-shrink: 1 !important;
}

.body .responsive-md .justify-content-md-start {
  justify-content: flex-start !important;
}

.body .responsive-md .justify-content-md-end {
  justify-content: flex-end !important;
}

.body .responsive-md .justify-content-md-center {
  justify-content: center !important;
}

.body .responsive-md .justify-content-md-between {
  justify-content: space-between !important;
}

.body .responsive-md .justify-content-md-around {
  justify-content: space-around !important;
}

.body .responsive-md .align-items-md-start {
  align-items: flex-start !important;
}

.body .responsive-md .align-items-md-end {
  align-items: flex-end !important;
}

.body .responsive-md .align-items-md-center {
  align-items: center !important;
}

.body .responsive-md .align-items-md-baseline {
  align-items: baseline !important;
}

.body .responsive-md .align-items-md-stretch {
  align-items: stretch !important;
}

.body .responsive-md .align-content-md-start {
  align-content: flex-start !important;
}

.body .responsive-md .align-content-md-end {
  align-content: flex-end !important;
}

.body .responsive-md .align-content-md-center {
  align-content: center !important;
}

.body .responsive-md .align-content-md-between {
  align-content: space-between !important;
}

.body .responsive-md .align-content-md-around {
  align-content: space-around !important;
}

.body .responsive-md .align-content-md-stretch {
  align-content: stretch !important;
}

.body .responsive-md .align-self-md-auto {
  align-self: auto !important;
}

.body .responsive-md .align-self-md-start {
  align-self: flex-start !important;
}

.body .responsive-md .align-self-md-end {
  align-self: flex-end !important;
}

.body .responsive-md .align-self-md-center {
  align-self: center !important;
}

.body .responsive-md .align-self-md-baseline {
  align-self: baseline !important;
}

.body .responsive-md .align-self-md-stretch {
  align-self: stretch !important;
}

.body .responsive-lg .flex-lg-row {
  flex-direction: row !important;
}

.body .responsive-lg .flex-lg-column {
  flex-direction: column !important;
}

.body .responsive-lg .flex-lg-row-reverse {
  flex-direction: row-reverse !important;
}

.body .responsive-lg .flex-lg-column-reverse {
  flex-direction: column-reverse !important;
}

.body .responsive-lg .flex-lg-wrap {
  flex-wrap: wrap !important;
}

.body .responsive-lg .flex-lg-nowrap {
  flex-wrap: nowrap !important;
}

.body .responsive-lg .flex-lg-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.body .responsive-lg .flex-lg-fill {
  flex: auto !important;
}

.body .responsive-lg .flex-lg-grow-0 {
  flex-grow: 0 !important;
}

.body .responsive-lg .flex-lg-grow-1 {
  flex-grow: 1 !important;
}

.body .responsive-lg .flex-lg-shrink-0 {
  flex-shrink: 0 !important;
}

.body .responsive-lg .flex-lg-shrink-1 {
  flex-shrink: 1 !important;
}

.body .responsive-lg .justify-content-lg-start {
  justify-content: flex-start !important;
}

.body .responsive-lg .justify-content-lg-end {
  justify-content: flex-end !important;
}

.body .responsive-lg .justify-content-lg-center {
  justify-content: center !important;
}

.body .responsive-lg .justify-content-lg-between {
  justify-content: space-between !important;
}

.body .responsive-lg .justify-content-lg-around {
  justify-content: space-around !important;
}

.body .responsive-lg .align-items-lg-start {
  align-items: flex-start !important;
}

.body .responsive-lg .align-items-lg-end {
  align-items: flex-end !important;
}

.body .responsive-lg .align-items-lg-center {
  align-items: center !important;
}

.body .responsive-lg .align-items-lg-baseline {
  align-items: baseline !important;
}

.body .responsive-lg .align-items-lg-stretch {
  align-items: stretch !important;
}

.body .responsive-lg .align-content-lg-start {
  align-content: flex-start !important;
}

.body .responsive-lg .align-content-lg-end {
  align-content: flex-end !important;
}

.body .responsive-lg .align-content-lg-center {
  align-content: center !important;
}

.body .responsive-lg .align-content-lg-between {
  align-content: space-between !important;
}

.body .responsive-lg .align-content-lg-around {
  align-content: space-around !important;
}

.body .responsive-lg .align-content-lg-stretch {
  align-content: stretch !important;
}

.body .responsive-lg .align-self-lg-auto {
  align-self: auto !important;
}

.body .responsive-lg .align-self-lg-start {
  align-self: flex-start !important;
}

.body .responsive-lg .align-self-lg-end {
  align-self: flex-end !important;
}

.body .responsive-lg .align-self-lg-center {
  align-self: center !important;
}

.body .responsive-lg .align-self-lg-baseline {
  align-self: baseline !important;
}

.body .responsive-lg .align-self-lg-stretch {
  align-self: stretch !important;
}

.body .responsive-xl .flex-xl-row {
  flex-direction: row !important;
}

.body .responsive-xl .flex-xl-column {
  flex-direction: column !important;
}

.body .responsive-xl .flex-xl-row-reverse {
  flex-direction: row-reverse !important;
}

.body .responsive-xl .flex-xl-column-reverse {
  flex-direction: column-reverse !important;
}

.body .responsive-xl .flex-xl-wrap {
  flex-wrap: wrap !important;
}

.body .responsive-xl .flex-xl-nowrap {
  flex-wrap: nowrap !important;
}

.body .responsive-xl .flex-xl-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.body .responsive-xl .flex-xl-fill {
  flex: auto !important;
}

.body .responsive-xl .flex-xl-grow-0 {
  flex-grow: 0 !important;
}

.body .responsive-xl .flex-xl-grow-1 {
  flex-grow: 1 !important;
}

.body .responsive-xl .flex-xl-shrink-0 {
  flex-shrink: 0 !important;
}

.body .responsive-xl .flex-xl-shrink-1 {
  flex-shrink: 1 !important;
}

.body .responsive-xl .justify-content-xl-start {
  justify-content: flex-start !important;
}

.body .responsive-xl .justify-content-xl-end {
  justify-content: flex-end !important;
}

.body .responsive-xl .justify-content-xl-center {
  justify-content: center !important;
}

.body .responsive-xl .justify-content-xl-between {
  justify-content: space-between !important;
}

.body .responsive-xl .justify-content-xl-around {
  justify-content: space-around !important;
}

.body .responsive-xl .align-items-xl-start {
  align-items: flex-start !important;
}

.body .responsive-xl .align-items-xl-end {
  align-items: flex-end !important;
}

.body .responsive-xl .align-items-xl-center {
  align-items: center !important;
}

.body .responsive-xl .align-items-xl-baseline {
  align-items: baseline !important;
}

.body .responsive-xl .align-items-xl-stretch {
  align-items: stretch !important;
}

.body .responsive-xl .align-content-xl-start {
  align-content: flex-start !important;
}

.body .responsive-xl .align-content-xl-end {
  align-content: flex-end !important;
}

.body .responsive-xl .align-content-xl-center {
  align-content: center !important;
}

.body .responsive-xl .align-content-xl-between {
  align-content: space-between !important;
}

.body .responsive-xl .align-content-xl-around {
  align-content: space-around !important;
}

.body .responsive-xl .align-content-xl-stretch {
  align-content: stretch !important;
}

.body .responsive-xl .align-self-xl-auto {
  align-self: auto !important;
}

.body .responsive-xl .align-self-xl-start {
  align-self: flex-start !important;
}

.body .responsive-xl .align-self-xl-end {
  align-self: flex-end !important;
}

.body .responsive-xl .align-self-xl-center {
  align-self: center !important;
}

.body .responsive-xl .align-self-xl-baseline {
  align-self: baseline !important;
}

.body .responsive-xl .align-self-xl-stretch {
  align-self: stretch !important;
}

.body .float-left {
  float: left !important;
}

.body .float-right {
  float: right !important;
}

.body .float-none {
  float: none !important;
}

.body .responsive-sm .float-sm-left {
  float: left !important;
}

.body .responsive-sm .float-sm-right {
  float: right !important;
}

.body .responsive-sm .float-sm-none {
  float: none !important;
}

.body .responsive-md .float-md-left {
  float: left !important;
}

.body .responsive-md .float-md-right {
  float: right !important;
}

.body .responsive-md .float-md-none {
  float: none !important;
}

.body .responsive-lg .float-lg-left {
  float: left !important;
}

.body .responsive-lg .float-lg-right {
  float: right !important;
}

.body .responsive-lg .float-lg-none {
  float: none !important;
}

.body .responsive-xl .float-xl-left {
  float: left !important;
}

.body .responsive-xl .float-xl-right {
  float: right !important;
}

.body .responsive-xl .float-xl-none {
  float: none !important;
}

.body .user-select-all {
  -webkit-user-select: all !important;
  user-select: all !important;
}

.body .user-select-auto {
  -webkit-user-select: auto !important;
  user-select: auto !important;
}

.body .user-select-none {
  -webkit-user-select: none !important;
  user-select: none !important;
}

.body .overflow-auto {
  overflow: auto !important;
}

.body .overflow-hidden {
  overflow: hidden !important;
}

.body .position-static {
  position: static !important;
}

.body .position-relative {
  position: relative !important;
}

.body .position-absolute {
  position: absolute !important;
}

.body .position-fixed {
  position: fixed !important;
}

.body .position-sticky {
  position: sticky !important;
}

.body .fixed-top {
  z-index: 1030;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.body .fixed-bottom {
  z-index: 1030;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@supports (position: sticky) {
  .body .sticky-top {
    z-index: 1020;
    position: sticky;
    top: 0;
  }
}

.body .sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.body .sr-only-focusable:active, .body .sr-only-focusable:focus {
  width: auto;
  height: auto;
  clip: auto;
  white-space: normal;
  position: static;
  overflow: visible;
}

.body .shadow-sm {
  box-shadow: 0 .125rem .25rem #00000013 !important;
}

.body .shadow {
  box-shadow: 0 .5rem 1rem #00000026 !important;
}

.body .shadow-lg {
  box-shadow: 0 1rem 3rem #0000002d !important;
}

.body .shadow-none {
  box-shadow: none !important;
}

.body .w-25 {
  width: 25% !important;
}

.body .w-50 {
  width: 50% !important;
}

.body .w-75 {
  width: 75% !important;
}

.body .w-100 {
  width: 100% !important;
}

.body .w-auto {
  width: auto !important;
}

.body .h-25 {
  height: 25% !important;
}

.body .h-50 {
  height: 50% !important;
}

.body .h-75 {
  height: 75% !important;
}

.body .h-100 {
  height: 100% !important;
}

.body .h-auto {
  height: auto !important;
}

.body .mw-100 {
  max-width: 100% !important;
}

.body .mh-100 {
  max-height: 100% !important;
}

.body .min-vw-100 {
  min-width: 100vw !important;
}

.body .min-vh-100 {
  min-height: 100vh !important;
}

.body .vw-100 {
  width: 100vw !important;
}

.body .vh-100 {
  height: 100vh !important;
}

.body .m-0 {
  margin: 0 !important;
}

.body .mt-0, .body .my-0 {
  margin-top: 0 !important;
}

.body .mr-0, .body .mx-0 {
  margin-right: 0 !important;
}

.body .mb-0, .body .my-0 {
  margin-bottom: 0 !important;
}

.body .ml-0, .body .mx-0 {
  margin-left: 0 !important;
}

.body .m-1 {
  margin: .25rem !important;
}

.body .mt-1, .body .my-1 {
  margin-top: .25rem !important;
}

.body .mr-1, .body .mx-1 {
  margin-right: .25rem !important;
}

.body .mb-1, .body .my-1 {
  margin-bottom: .25rem !important;
}

.body .ml-1, .body .mx-1 {
  margin-left: .25rem !important;
}

.body .m-2 {
  margin: .5rem !important;
}

.body .mt-2, .body .my-2 {
  margin-top: .5rem !important;
}

.body .mr-2, .body .mx-2 {
  margin-right: .5rem !important;
}

.body .mb-2, .body .my-2 {
  margin-bottom: .5rem !important;
}

.body .ml-2, .body .mx-2 {
  margin-left: .5rem !important;
}

.body .m-3 {
  margin: 1rem !important;
}

.body .mt-3, .body .my-3 {
  margin-top: 1rem !important;
}

.body .mr-3, .body .mx-3 {
  margin-right: 1rem !important;
}

.body .mb-3, .body .my-3 {
  margin-bottom: 1rem !important;
}

.body .ml-3, .body .mx-3 {
  margin-left: 1rem !important;
}

.body .m-4 {
  margin: 1.5rem !important;
}

.body .mt-4, .body .my-4 {
  margin-top: 1.5rem !important;
}

.body .mr-4, .body .mx-4 {
  margin-right: 1.5rem !important;
}

.body .mb-4, .body .my-4 {
  margin-bottom: 1.5rem !important;
}

.body .ml-4, .body .mx-4 {
  margin-left: 1.5rem !important;
}

.body .m-5 {
  margin: 3rem !important;
}

.body .mt-5, .body .my-5 {
  margin-top: 3rem !important;
}

.body .mr-5, .body .mx-5 {
  margin-right: 3rem !important;
}

.body .mb-5, .body .my-5 {
  margin-bottom: 3rem !important;
}

.body .ml-5, .body .mx-5 {
  margin-left: 3rem !important;
}

.body .p-0 {
  padding: 0 !important;
}

.body .pt-0, .body .py-0 {
  padding-top: 0 !important;
}

.body .pr-0, .body .px-0 {
  padding-right: 0 !important;
}

.body .pb-0, .body .py-0 {
  padding-bottom: 0 !important;
}

.body .pl-0, .body .px-0 {
  padding-left: 0 !important;
}

.body .p-1 {
  padding: .25rem !important;
}

.body .pt-1, .body .py-1 {
  padding-top: .25rem !important;
}

.body .pr-1, .body .px-1 {
  padding-right: .25rem !important;
}

.body .pb-1, .body .py-1 {
  padding-bottom: .25rem !important;
}

.body .pl-1, .body .px-1 {
  padding-left: .25rem !important;
}

.body .p-2 {
  padding: .5rem !important;
}

.body .pt-2, .body .py-2 {
  padding-top: .5rem !important;
}

.body .pr-2, .body .px-2 {
  padding-right: .5rem !important;
}

.body .pb-2, .body .py-2 {
  padding-bottom: .5rem !important;
}

.body .pl-2, .body .px-2 {
  padding-left: .5rem !important;
}

.body .p-3 {
  padding: 1rem !important;
}

.body .pt-3, .body .py-3 {
  padding-top: 1rem !important;
}

.body .pr-3, .body .px-3 {
  padding-right: 1rem !important;
}

.body .pb-3, .body .py-3 {
  padding-bottom: 1rem !important;
}

.body .pl-3, .body .px-3 {
  padding-left: 1rem !important;
}

.body .p-4 {
  padding: 1.5rem !important;
}

.body .pt-4, .body .py-4 {
  padding-top: 1.5rem !important;
}

.body .pr-4, .body .px-4 {
  padding-right: 1.5rem !important;
}

.body .pb-4, .body .py-4 {
  padding-bottom: 1.5rem !important;
}

.body .pl-4, .body .px-4 {
  padding-left: 1.5rem !important;
}

.body .p-5 {
  padding: 3rem !important;
}

.body .pt-5, .body .py-5 {
  padding-top: 3rem !important;
}

.body .pr-5, .body .px-5 {
  padding-right: 3rem !important;
}

.body .pb-5, .body .py-5 {
  padding-bottom: 3rem !important;
}

.body .pl-5, .body .px-5 {
  padding-left: 3rem !important;
}

.body .m-n1 {
  margin: -.25rem !important;
}

.body .mt-n1, .body .my-n1 {
  margin-top: -.25rem !important;
}

.body .mr-n1, .body .mx-n1 {
  margin-right: -.25rem !important;
}

.body .mb-n1, .body .my-n1 {
  margin-bottom: -.25rem !important;
}

.body .ml-n1, .body .mx-n1 {
  margin-left: -.25rem !important;
}

.body .m-n2 {
  margin: -.5rem !important;
}

.body .mt-n2, .body .my-n2 {
  margin-top: -.5rem !important;
}

.body .mr-n2, .body .mx-n2 {
  margin-right: -.5rem !important;
}

.body .mb-n2, .body .my-n2 {
  margin-bottom: -.5rem !important;
}

.body .ml-n2, .body .mx-n2 {
  margin-left: -.5rem !important;
}

.body .m-n3 {
  margin: -1rem !important;
}

.body .mt-n3, .body .my-n3 {
  margin-top: -1rem !important;
}

.body .mr-n3, .body .mx-n3 {
  margin-right: -1rem !important;
}

.body .mb-n3, .body .my-n3 {
  margin-bottom: -1rem !important;
}

.body .ml-n3, .body .mx-n3 {
  margin-left: -1rem !important;
}

.body .m-n4 {
  margin: -1.5rem !important;
}

.body .mt-n4, .body .my-n4 {
  margin-top: -1.5rem !important;
}

.body .mr-n4, .body .mx-n4 {
  margin-right: -1.5rem !important;
}

.body .mb-n4, .body .my-n4 {
  margin-bottom: -1.5rem !important;
}

.body .ml-n4, .body .mx-n4 {
  margin-left: -1.5rem !important;
}

.body .m-n5 {
  margin: -3rem !important;
}

.body .mt-n5, .body .my-n5 {
  margin-top: -3rem !important;
}

.body .mr-n5, .body .mx-n5 {
  margin-right: -3rem !important;
}

.body .mb-n5, .body .my-n5 {
  margin-bottom: -3rem !important;
}

.body .ml-n5, .body .mx-n5 {
  margin-left: -3rem !important;
}

.body .m-auto {
  margin: auto !important;
}

.body .mt-auto, .body .my-auto {
  margin-top: auto !important;
}

.body .mr-auto, .body .mx-auto {
  margin-right: auto !important;
}

.body .mb-auto, .body .my-auto {
  margin-bottom: auto !important;
}

.body .ml-auto, .body .mx-auto {
  margin-left: auto !important;
}

.body .responsive-sm .m-sm-0 {
  margin: 0 !important;
}

.body .responsive-sm .mt-sm-0, .body .responsive-sm .my-sm-0 {
  margin-top: 0 !important;
}

.body .responsive-sm .mr-sm-0, .body .responsive-sm .mx-sm-0 {
  margin-right: 0 !important;
}

.body .responsive-sm .mb-sm-0, .body .responsive-sm .my-sm-0 {
  margin-bottom: 0 !important;
}

.body .responsive-sm .ml-sm-0, .body .responsive-sm .mx-sm-0 {
  margin-left: 0 !important;
}

.body .responsive-sm .m-sm-1 {
  margin: .25rem !important;
}

.body .responsive-sm .mt-sm-1, .body .responsive-sm .my-sm-1 {
  margin-top: .25rem !important;
}

.body .responsive-sm .mr-sm-1, .body .responsive-sm .mx-sm-1 {
  margin-right: .25rem !important;
}

.body .responsive-sm .mb-sm-1, .body .responsive-sm .my-sm-1 {
  margin-bottom: .25rem !important;
}

.body .responsive-sm .ml-sm-1, .body .responsive-sm .mx-sm-1 {
  margin-left: .25rem !important;
}

.body .responsive-sm .m-sm-2 {
  margin: .5rem !important;
}

.body .responsive-sm .mt-sm-2, .body .responsive-sm .my-sm-2 {
  margin-top: .5rem !important;
}

.body .responsive-sm .mr-sm-2, .body .responsive-sm .mx-sm-2 {
  margin-right: .5rem !important;
}

.body .responsive-sm .mb-sm-2, .body .responsive-sm .my-sm-2 {
  margin-bottom: .5rem !important;
}

.body .responsive-sm .ml-sm-2, .body .responsive-sm .mx-sm-2 {
  margin-left: .5rem !important;
}

.body .responsive-sm .m-sm-3 {
  margin: 1rem !important;
}

.body .responsive-sm .mt-sm-3, .body .responsive-sm .my-sm-3 {
  margin-top: 1rem !important;
}

.body .responsive-sm .mr-sm-3, .body .responsive-sm .mx-sm-3 {
  margin-right: 1rem !important;
}

.body .responsive-sm .mb-sm-3, .body .responsive-sm .my-sm-3 {
  margin-bottom: 1rem !important;
}

.body .responsive-sm .ml-sm-3, .body .responsive-sm .mx-sm-3 {
  margin-left: 1rem !important;
}

.body .responsive-sm .m-sm-4 {
  margin: 1.5rem !important;
}

.body .responsive-sm .mt-sm-4, .body .responsive-sm .my-sm-4 {
  margin-top: 1.5rem !important;
}

.body .responsive-sm .mr-sm-4, .body .responsive-sm .mx-sm-4 {
  margin-right: 1.5rem !important;
}

.body .responsive-sm .mb-sm-4, .body .responsive-sm .my-sm-4 {
  margin-bottom: 1.5rem !important;
}

.body .responsive-sm .ml-sm-4, .body .responsive-sm .mx-sm-4 {
  margin-left: 1.5rem !important;
}

.body .responsive-sm .m-sm-5 {
  margin: 3rem !important;
}

.body .responsive-sm .mt-sm-5, .body .responsive-sm .my-sm-5 {
  margin-top: 3rem !important;
}

.body .responsive-sm .mr-sm-5, .body .responsive-sm .mx-sm-5 {
  margin-right: 3rem !important;
}

.body .responsive-sm .mb-sm-5, .body .responsive-sm .my-sm-5 {
  margin-bottom: 3rem !important;
}

.body .responsive-sm .ml-sm-5, .body .responsive-sm .mx-sm-5 {
  margin-left: 3rem !important;
}

.body .responsive-sm .p-sm-0 {
  padding: 0 !important;
}

.body .responsive-sm .pt-sm-0, .body .responsive-sm .py-sm-0 {
  padding-top: 0 !important;
}

.body .responsive-sm .pr-sm-0, .body .responsive-sm .px-sm-0 {
  padding-right: 0 !important;
}

.body .responsive-sm .pb-sm-0, .body .responsive-sm .py-sm-0 {
  padding-bottom: 0 !important;
}

.body .responsive-sm .pl-sm-0, .body .responsive-sm .px-sm-0 {
  padding-left: 0 !important;
}

.body .responsive-sm .p-sm-1 {
  padding: .25rem !important;
}

.body .responsive-sm .pt-sm-1, .body .responsive-sm .py-sm-1 {
  padding-top: .25rem !important;
}

.body .responsive-sm .pr-sm-1, .body .responsive-sm .px-sm-1 {
  padding-right: .25rem !important;
}

.body .responsive-sm .pb-sm-1, .body .responsive-sm .py-sm-1 {
  padding-bottom: .25rem !important;
}

.body .responsive-sm .pl-sm-1, .body .responsive-sm .px-sm-1 {
  padding-left: .25rem !important;
}

.body .responsive-sm .p-sm-2 {
  padding: .5rem !important;
}

.body .responsive-sm .pt-sm-2, .body .responsive-sm .py-sm-2 {
  padding-top: .5rem !important;
}

.body .responsive-sm .pr-sm-2, .body .responsive-sm .px-sm-2 {
  padding-right: .5rem !important;
}

.body .responsive-sm .pb-sm-2, .body .responsive-sm .py-sm-2 {
  padding-bottom: .5rem !important;
}

.body .responsive-sm .pl-sm-2, .body .responsive-sm .px-sm-2 {
  padding-left: .5rem !important;
}

.body .responsive-sm .p-sm-3 {
  padding: 1rem !important;
}

.body .responsive-sm .pt-sm-3, .body .responsive-sm .py-sm-3 {
  padding-top: 1rem !important;
}

.body .responsive-sm .pr-sm-3, .body .responsive-sm .px-sm-3 {
  padding-right: 1rem !important;
}

.body .responsive-sm .pb-sm-3, .body .responsive-sm .py-sm-3 {
  padding-bottom: 1rem !important;
}

.body .responsive-sm .pl-sm-3, .body .responsive-sm .px-sm-3 {
  padding-left: 1rem !important;
}

.body .responsive-sm .p-sm-4 {
  padding: 1.5rem !important;
}

.body .responsive-sm .pt-sm-4, .body .responsive-sm .py-sm-4 {
  padding-top: 1.5rem !important;
}

.body .responsive-sm .pr-sm-4, .body .responsive-sm .px-sm-4 {
  padding-right: 1.5rem !important;
}

.body .responsive-sm .pb-sm-4, .body .responsive-sm .py-sm-4 {
  padding-bottom: 1.5rem !important;
}

.body .responsive-sm .pl-sm-4, .body .responsive-sm .px-sm-4 {
  padding-left: 1.5rem !important;
}

.body .responsive-sm .p-sm-5 {
  padding: 3rem !important;
}

.body .responsive-sm .pt-sm-5, .body .responsive-sm .py-sm-5 {
  padding-top: 3rem !important;
}

.body .responsive-sm .pr-sm-5, .body .responsive-sm .px-sm-5 {
  padding-right: 3rem !important;
}

.body .responsive-sm .pb-sm-5, .body .responsive-sm .py-sm-5 {
  padding-bottom: 3rem !important;
}

.body .responsive-sm .pl-sm-5, .body .responsive-sm .px-sm-5 {
  padding-left: 3rem !important;
}

.body .responsive-sm .m-sm-n1 {
  margin: -.25rem !important;
}

.body .responsive-sm .mt-sm-n1, .body .responsive-sm .my-sm-n1 {
  margin-top: -.25rem !important;
}

.body .responsive-sm .mr-sm-n1, .body .responsive-sm .mx-sm-n1 {
  margin-right: -.25rem !important;
}

.body .responsive-sm .mb-sm-n1, .body .responsive-sm .my-sm-n1 {
  margin-bottom: -.25rem !important;
}

.body .responsive-sm .ml-sm-n1, .body .responsive-sm .mx-sm-n1 {
  margin-left: -.25rem !important;
}

.body .responsive-sm .m-sm-n2 {
  margin: -.5rem !important;
}

.body .responsive-sm .mt-sm-n2, .body .responsive-sm .my-sm-n2 {
  margin-top: -.5rem !important;
}

.body .responsive-sm .mr-sm-n2, .body .responsive-sm .mx-sm-n2 {
  margin-right: -.5rem !important;
}

.body .responsive-sm .mb-sm-n2, .body .responsive-sm .my-sm-n2 {
  margin-bottom: -.5rem !important;
}

.body .responsive-sm .ml-sm-n2, .body .responsive-sm .mx-sm-n2 {
  margin-left: -.5rem !important;
}

.body .responsive-sm .m-sm-n3 {
  margin: -1rem !important;
}

.body .responsive-sm .mt-sm-n3, .body .responsive-sm .my-sm-n3 {
  margin-top: -1rem !important;
}

.body .responsive-sm .mr-sm-n3, .body .responsive-sm .mx-sm-n3 {
  margin-right: -1rem !important;
}

.body .responsive-sm .mb-sm-n3, .body .responsive-sm .my-sm-n3 {
  margin-bottom: -1rem !important;
}

.body .responsive-sm .ml-sm-n3, .body .responsive-sm .mx-sm-n3 {
  margin-left: -1rem !important;
}

.body .responsive-sm .m-sm-n4 {
  margin: -1.5rem !important;
}

.body .responsive-sm .mt-sm-n4, .body .responsive-sm .my-sm-n4 {
  margin-top: -1.5rem !important;
}

.body .responsive-sm .mr-sm-n4, .body .responsive-sm .mx-sm-n4 {
  margin-right: -1.5rem !important;
}

.body .responsive-sm .mb-sm-n4, .body .responsive-sm .my-sm-n4 {
  margin-bottom: -1.5rem !important;
}

.body .responsive-sm .ml-sm-n4, .body .responsive-sm .mx-sm-n4 {
  margin-left: -1.5rem !important;
}

.body .responsive-sm .m-sm-n5 {
  margin: -3rem !important;
}

.body .responsive-sm .mt-sm-n5, .body .responsive-sm .my-sm-n5 {
  margin-top: -3rem !important;
}

.body .responsive-sm .mr-sm-n5, .body .responsive-sm .mx-sm-n5 {
  margin-right: -3rem !important;
}

.body .responsive-sm .mb-sm-n5, .body .responsive-sm .my-sm-n5 {
  margin-bottom: -3rem !important;
}

.body .responsive-sm .ml-sm-n5, .body .responsive-sm .mx-sm-n5 {
  margin-left: -3rem !important;
}

.body .responsive-sm .m-sm-auto {
  margin: auto !important;
}

.body .responsive-sm .mt-sm-auto, .body .responsive-sm .my-sm-auto {
  margin-top: auto !important;
}

.body .responsive-sm .mr-sm-auto, .body .responsive-sm .mx-sm-auto {
  margin-right: auto !important;
}

.body .responsive-sm .mb-sm-auto, .body .responsive-sm .my-sm-auto {
  margin-bottom: auto !important;
}

.body .responsive-sm .ml-sm-auto, .body .responsive-sm .mx-sm-auto {
  margin-left: auto !important;
}

.body .responsive-md .m-md-0 {
  margin: 0 !important;
}

.body .responsive-md .mt-md-0, .body .responsive-md .my-md-0 {
  margin-top: 0 !important;
}

.body .responsive-md .mr-md-0, .body .responsive-md .mx-md-0 {
  margin-right: 0 !important;
}

.body .responsive-md .mb-md-0, .body .responsive-md .my-md-0 {
  margin-bottom: 0 !important;
}

.body .responsive-md .ml-md-0, .body .responsive-md .mx-md-0 {
  margin-left: 0 !important;
}

.body .responsive-md .m-md-1 {
  margin: .25rem !important;
}

.body .responsive-md .mt-md-1, .body .responsive-md .my-md-1 {
  margin-top: .25rem !important;
}

.body .responsive-md .mr-md-1, .body .responsive-md .mx-md-1 {
  margin-right: .25rem !important;
}

.body .responsive-md .mb-md-1, .body .responsive-md .my-md-1 {
  margin-bottom: .25rem !important;
}

.body .responsive-md .ml-md-1, .body .responsive-md .mx-md-1 {
  margin-left: .25rem !important;
}

.body .responsive-md .m-md-2 {
  margin: .5rem !important;
}

.body .responsive-md .mt-md-2, .body .responsive-md .my-md-2 {
  margin-top: .5rem !important;
}

.body .responsive-md .mr-md-2, .body .responsive-md .mx-md-2 {
  margin-right: .5rem !important;
}

.body .responsive-md .mb-md-2, .body .responsive-md .my-md-2 {
  margin-bottom: .5rem !important;
}

.body .responsive-md .ml-md-2, .body .responsive-md .mx-md-2 {
  margin-left: .5rem !important;
}

.body .responsive-md .m-md-3 {
  margin: 1rem !important;
}

.body .responsive-md .mt-md-3, .body .responsive-md .my-md-3 {
  margin-top: 1rem !important;
}

.body .responsive-md .mr-md-3, .body .responsive-md .mx-md-3 {
  margin-right: 1rem !important;
}

.body .responsive-md .mb-md-3, .body .responsive-md .my-md-3 {
  margin-bottom: 1rem !important;
}

.body .responsive-md .ml-md-3, .body .responsive-md .mx-md-3 {
  margin-left: 1rem !important;
}

.body .responsive-md .m-md-4 {
  margin: 1.5rem !important;
}

.body .responsive-md .mt-md-4, .body .responsive-md .my-md-4 {
  margin-top: 1.5rem !important;
}

.body .responsive-md .mr-md-4, .body .responsive-md .mx-md-4 {
  margin-right: 1.5rem !important;
}

.body .responsive-md .mb-md-4, .body .responsive-md .my-md-4 {
  margin-bottom: 1.5rem !important;
}

.body .responsive-md .ml-md-4, .body .responsive-md .mx-md-4 {
  margin-left: 1.5rem !important;
}

.body .responsive-md .m-md-5 {
  margin: 3rem !important;
}

.body .responsive-md .mt-md-5, .body .responsive-md .my-md-5 {
  margin-top: 3rem !important;
}

.body .responsive-md .mr-md-5, .body .responsive-md .mx-md-5 {
  margin-right: 3rem !important;
}

.body .responsive-md .mb-md-5, .body .responsive-md .my-md-5 {
  margin-bottom: 3rem !important;
}

.body .responsive-md .ml-md-5, .body .responsive-md .mx-md-5 {
  margin-left: 3rem !important;
}

.body .responsive-md .p-md-0 {
  padding: 0 !important;
}

.body .responsive-md .pt-md-0, .body .responsive-md .py-md-0 {
  padding-top: 0 !important;
}

.body .responsive-md .pr-md-0, .body .responsive-md .px-md-0 {
  padding-right: 0 !important;
}

.body .responsive-md .pb-md-0, .body .responsive-md .py-md-0 {
  padding-bottom: 0 !important;
}

.body .responsive-md .pl-md-0, .body .responsive-md .px-md-0 {
  padding-left: 0 !important;
}

.body .responsive-md .p-md-1 {
  padding: .25rem !important;
}

.body .responsive-md .pt-md-1, .body .responsive-md .py-md-1 {
  padding-top: .25rem !important;
}

.body .responsive-md .pr-md-1, .body .responsive-md .px-md-1 {
  padding-right: .25rem !important;
}

.body .responsive-md .pb-md-1, .body .responsive-md .py-md-1 {
  padding-bottom: .25rem !important;
}

.body .responsive-md .pl-md-1, .body .responsive-md .px-md-1 {
  padding-left: .25rem !important;
}

.body .responsive-md .p-md-2 {
  padding: .5rem !important;
}

.body .responsive-md .pt-md-2, .body .responsive-md .py-md-2 {
  padding-top: .5rem !important;
}

.body .responsive-md .pr-md-2, .body .responsive-md .px-md-2 {
  padding-right: .5rem !important;
}

.body .responsive-md .pb-md-2, .body .responsive-md .py-md-2 {
  padding-bottom: .5rem !important;
}

.body .responsive-md .pl-md-2, .body .responsive-md .px-md-2 {
  padding-left: .5rem !important;
}

.body .responsive-md .p-md-3 {
  padding: 1rem !important;
}

.body .responsive-md .pt-md-3, .body .responsive-md .py-md-3 {
  padding-top: 1rem !important;
}

.body .responsive-md .pr-md-3, .body .responsive-md .px-md-3 {
  padding-right: 1rem !important;
}

.body .responsive-md .pb-md-3, .body .responsive-md .py-md-3 {
  padding-bottom: 1rem !important;
}

.body .responsive-md .pl-md-3, .body .responsive-md .px-md-3 {
  padding-left: 1rem !important;
}

.body .responsive-md .p-md-4 {
  padding: 1.5rem !important;
}

.body .responsive-md .pt-md-4, .body .responsive-md .py-md-4 {
  padding-top: 1.5rem !important;
}

.body .responsive-md .pr-md-4, .body .responsive-md .px-md-4 {
  padding-right: 1.5rem !important;
}

.body .responsive-md .pb-md-4, .body .responsive-md .py-md-4 {
  padding-bottom: 1.5rem !important;
}

.body .responsive-md .pl-md-4, .body .responsive-md .px-md-4 {
  padding-left: 1.5rem !important;
}

.body .responsive-md .p-md-5 {
  padding: 3rem !important;
}

.body .responsive-md .pt-md-5, .body .responsive-md .py-md-5 {
  padding-top: 3rem !important;
}

.body .responsive-md .pr-md-5, .body .responsive-md .px-md-5 {
  padding-right: 3rem !important;
}

.body .responsive-md .pb-md-5, .body .responsive-md .py-md-5 {
  padding-bottom: 3rem !important;
}

.body .responsive-md .pl-md-5, .body .responsive-md .px-md-5 {
  padding-left: 3rem !important;
}

.body .responsive-md .m-md-n1 {
  margin: -.25rem !important;
}

.body .responsive-md .mt-md-n1, .body .responsive-md .my-md-n1 {
  margin-top: -.25rem !important;
}

.body .responsive-md .mr-md-n1, .body .responsive-md .mx-md-n1 {
  margin-right: -.25rem !important;
}

.body .responsive-md .mb-md-n1, .body .responsive-md .my-md-n1 {
  margin-bottom: -.25rem !important;
}

.body .responsive-md .ml-md-n1, .body .responsive-md .mx-md-n1 {
  margin-left: -.25rem !important;
}

.body .responsive-md .m-md-n2 {
  margin: -.5rem !important;
}

.body .responsive-md .mt-md-n2, .body .responsive-md .my-md-n2 {
  margin-top: -.5rem !important;
}

.body .responsive-md .mr-md-n2, .body .responsive-md .mx-md-n2 {
  margin-right: -.5rem !important;
}

.body .responsive-md .mb-md-n2, .body .responsive-md .my-md-n2 {
  margin-bottom: -.5rem !important;
}

.body .responsive-md .ml-md-n2, .body .responsive-md .mx-md-n2 {
  margin-left: -.5rem !important;
}

.body .responsive-md .m-md-n3 {
  margin: -1rem !important;
}

.body .responsive-md .mt-md-n3, .body .responsive-md .my-md-n3 {
  margin-top: -1rem !important;
}

.body .responsive-md .mr-md-n3, .body .responsive-md .mx-md-n3 {
  margin-right: -1rem !important;
}

.body .responsive-md .mb-md-n3, .body .responsive-md .my-md-n3 {
  margin-bottom: -1rem !important;
}

.body .responsive-md .ml-md-n3, .body .responsive-md .mx-md-n3 {
  margin-left: -1rem !important;
}

.body .responsive-md .m-md-n4 {
  margin: -1.5rem !important;
}

.body .responsive-md .mt-md-n4, .body .responsive-md .my-md-n4 {
  margin-top: -1.5rem !important;
}

.body .responsive-md .mr-md-n4, .body .responsive-md .mx-md-n4 {
  margin-right: -1.5rem !important;
}

.body .responsive-md .mb-md-n4, .body .responsive-md .my-md-n4 {
  margin-bottom: -1.5rem !important;
}

.body .responsive-md .ml-md-n4, .body .responsive-md .mx-md-n4 {
  margin-left: -1.5rem !important;
}

.body .responsive-md .m-md-n5 {
  margin: -3rem !important;
}

.body .responsive-md .mt-md-n5, .body .responsive-md .my-md-n5 {
  margin-top: -3rem !important;
}

.body .responsive-md .mr-md-n5, .body .responsive-md .mx-md-n5 {
  margin-right: -3rem !important;
}

.body .responsive-md .mb-md-n5, .body .responsive-md .my-md-n5 {
  margin-bottom: -3rem !important;
}

.body .responsive-md .ml-md-n5, .body .responsive-md .mx-md-n5 {
  margin-left: -3rem !important;
}

.body .responsive-md .m-md-auto {
  margin: auto !important;
}

.body .responsive-md .mt-md-auto, .body .responsive-md .my-md-auto {
  margin-top: auto !important;
}

.body .responsive-md .mr-md-auto, .body .responsive-md .mx-md-auto {
  margin-right: auto !important;
}

.body .responsive-md .mb-md-auto, .body .responsive-md .my-md-auto {
  margin-bottom: auto !important;
}

.body .responsive-md .ml-md-auto, .body .responsive-md .mx-md-auto {
  margin-left: auto !important;
}

.body .responsive-lg .m-lg-0 {
  margin: 0 !important;
}

.body .responsive-lg .mt-lg-0, .body .responsive-lg .my-lg-0 {
  margin-top: 0 !important;
}

.body .responsive-lg .mr-lg-0, .body .responsive-lg .mx-lg-0 {
  margin-right: 0 !important;
}

.body .responsive-lg .mb-lg-0, .body .responsive-lg .my-lg-0 {
  margin-bottom: 0 !important;
}

.body .responsive-lg .ml-lg-0, .body .responsive-lg .mx-lg-0 {
  margin-left: 0 !important;
}

.body .responsive-lg .m-lg-1 {
  margin: .25rem !important;
}

.body .responsive-lg .mt-lg-1, .body .responsive-lg .my-lg-1 {
  margin-top: .25rem !important;
}

.body .responsive-lg .mr-lg-1, .body .responsive-lg .mx-lg-1 {
  margin-right: .25rem !important;
}

.body .responsive-lg .mb-lg-1, .body .responsive-lg .my-lg-1 {
  margin-bottom: .25rem !important;
}

.body .responsive-lg .ml-lg-1, .body .responsive-lg .mx-lg-1 {
  margin-left: .25rem !important;
}

.body .responsive-lg .m-lg-2 {
  margin: .5rem !important;
}

.body .responsive-lg .mt-lg-2, .body .responsive-lg .my-lg-2 {
  margin-top: .5rem !important;
}

.body .responsive-lg .mr-lg-2, .body .responsive-lg .mx-lg-2 {
  margin-right: .5rem !important;
}

.body .responsive-lg .mb-lg-2, .body .responsive-lg .my-lg-2 {
  margin-bottom: .5rem !important;
}

.body .responsive-lg .ml-lg-2, .body .responsive-lg .mx-lg-2 {
  margin-left: .5rem !important;
}

.body .responsive-lg .m-lg-3 {
  margin: 1rem !important;
}

.body .responsive-lg .mt-lg-3, .body .responsive-lg .my-lg-3 {
  margin-top: 1rem !important;
}

.body .responsive-lg .mr-lg-3, .body .responsive-lg .mx-lg-3 {
  margin-right: 1rem !important;
}

.body .responsive-lg .mb-lg-3, .body .responsive-lg .my-lg-3 {
  margin-bottom: 1rem !important;
}

.body .responsive-lg .ml-lg-3, .body .responsive-lg .mx-lg-3 {
  margin-left: 1rem !important;
}

.body .responsive-lg .m-lg-4 {
  margin: 1.5rem !important;
}

.body .responsive-lg .mt-lg-4, .body .responsive-lg .my-lg-4 {
  margin-top: 1.5rem !important;
}

.body .responsive-lg .mr-lg-4, .body .responsive-lg .mx-lg-4 {
  margin-right: 1.5rem !important;
}

.body .responsive-lg .mb-lg-4, .body .responsive-lg .my-lg-4 {
  margin-bottom: 1.5rem !important;
}

.body .responsive-lg .ml-lg-4, .body .responsive-lg .mx-lg-4 {
  margin-left: 1.5rem !important;
}

.body .responsive-lg .m-lg-5 {
  margin: 3rem !important;
}

.body .responsive-lg .mt-lg-5, .body .responsive-lg .my-lg-5 {
  margin-top: 3rem !important;
}

.body .responsive-lg .mr-lg-5, .body .responsive-lg .mx-lg-5 {
  margin-right: 3rem !important;
}

.body .responsive-lg .mb-lg-5, .body .responsive-lg .my-lg-5 {
  margin-bottom: 3rem !important;
}

.body .responsive-lg .ml-lg-5, .body .responsive-lg .mx-lg-5 {
  margin-left: 3rem !important;
}

.body .responsive-lg .p-lg-0 {
  padding: 0 !important;
}

.body .responsive-lg .pt-lg-0, .body .responsive-lg .py-lg-0 {
  padding-top: 0 !important;
}

.body .responsive-lg .pr-lg-0, .body .responsive-lg .px-lg-0 {
  padding-right: 0 !important;
}

.body .responsive-lg .pb-lg-0, .body .responsive-lg .py-lg-0 {
  padding-bottom: 0 !important;
}

.body .responsive-lg .pl-lg-0, .body .responsive-lg .px-lg-0 {
  padding-left: 0 !important;
}

.body .responsive-lg .p-lg-1 {
  padding: .25rem !important;
}

.body .responsive-lg .pt-lg-1, .body .responsive-lg .py-lg-1 {
  padding-top: .25rem !important;
}

.body .responsive-lg .pr-lg-1, .body .responsive-lg .px-lg-1 {
  padding-right: .25rem !important;
}

.body .responsive-lg .pb-lg-1, .body .responsive-lg .py-lg-1 {
  padding-bottom: .25rem !important;
}

.body .responsive-lg .pl-lg-1, .body .responsive-lg .px-lg-1 {
  padding-left: .25rem !important;
}

.body .responsive-lg .p-lg-2 {
  padding: .5rem !important;
}

.body .responsive-lg .pt-lg-2, .body .responsive-lg .py-lg-2 {
  padding-top: .5rem !important;
}

.body .responsive-lg .pr-lg-2, .body .responsive-lg .px-lg-2 {
  padding-right: .5rem !important;
}

.body .responsive-lg .pb-lg-2, .body .responsive-lg .py-lg-2 {
  padding-bottom: .5rem !important;
}

.body .responsive-lg .pl-lg-2, .body .responsive-lg .px-lg-2 {
  padding-left: .5rem !important;
}

.body .responsive-lg .p-lg-3 {
  padding: 1rem !important;
}

.body .responsive-lg .pt-lg-3, .body .responsive-lg .py-lg-3 {
  padding-top: 1rem !important;
}

.body .responsive-lg .pr-lg-3, .body .responsive-lg .px-lg-3 {
  padding-right: 1rem !important;
}

.body .responsive-lg .pb-lg-3, .body .responsive-lg .py-lg-3 {
  padding-bottom: 1rem !important;
}

.body .responsive-lg .pl-lg-3, .body .responsive-lg .px-lg-3 {
  padding-left: 1rem !important;
}

.body .responsive-lg .p-lg-4 {
  padding: 1.5rem !important;
}

.body .responsive-lg .pt-lg-4, .body .responsive-lg .py-lg-4 {
  padding-top: 1.5rem !important;
}

.body .responsive-lg .pr-lg-4, .body .responsive-lg .px-lg-4 {
  padding-right: 1.5rem !important;
}

.body .responsive-lg .pb-lg-4, .body .responsive-lg .py-lg-4 {
  padding-bottom: 1.5rem !important;
}

.body .responsive-lg .pl-lg-4, .body .responsive-lg .px-lg-4 {
  padding-left: 1.5rem !important;
}

.body .responsive-lg .p-lg-5 {
  padding: 3rem !important;
}

.body .responsive-lg .pt-lg-5, .body .responsive-lg .py-lg-5 {
  padding-top: 3rem !important;
}

.body .responsive-lg .pr-lg-5, .body .responsive-lg .px-lg-5 {
  padding-right: 3rem !important;
}

.body .responsive-lg .pb-lg-5, .body .responsive-lg .py-lg-5 {
  padding-bottom: 3rem !important;
}

.body .responsive-lg .pl-lg-5, .body .responsive-lg .px-lg-5 {
  padding-left: 3rem !important;
}

.body .responsive-lg .m-lg-n1 {
  margin: -.25rem !important;
}

.body .responsive-lg .mt-lg-n1, .body .responsive-lg .my-lg-n1 {
  margin-top: -.25rem !important;
}

.body .responsive-lg .mr-lg-n1, .body .responsive-lg .mx-lg-n1 {
  margin-right: -.25rem !important;
}

.body .responsive-lg .mb-lg-n1, .body .responsive-lg .my-lg-n1 {
  margin-bottom: -.25rem !important;
}

.body .responsive-lg .ml-lg-n1, .body .responsive-lg .mx-lg-n1 {
  margin-left: -.25rem !important;
}

.body .responsive-lg .m-lg-n2 {
  margin: -.5rem !important;
}

.body .responsive-lg .mt-lg-n2, .body .responsive-lg .my-lg-n2 {
  margin-top: -.5rem !important;
}

.body .responsive-lg .mr-lg-n2, .body .responsive-lg .mx-lg-n2 {
  margin-right: -.5rem !important;
}

.body .responsive-lg .mb-lg-n2, .body .responsive-lg .my-lg-n2 {
  margin-bottom: -.5rem !important;
}

.body .responsive-lg .ml-lg-n2, .body .responsive-lg .mx-lg-n2 {
  margin-left: -.5rem !important;
}

.body .responsive-lg .m-lg-n3 {
  margin: -1rem !important;
}

.body .responsive-lg .mt-lg-n3, .body .responsive-lg .my-lg-n3 {
  margin-top: -1rem !important;
}

.body .responsive-lg .mr-lg-n3, .body .responsive-lg .mx-lg-n3 {
  margin-right: -1rem !important;
}

.body .responsive-lg .mb-lg-n3, .body .responsive-lg .my-lg-n3 {
  margin-bottom: -1rem !important;
}

.body .responsive-lg .ml-lg-n3, .body .responsive-lg .mx-lg-n3 {
  margin-left: -1rem !important;
}

.body .responsive-lg .m-lg-n4 {
  margin: -1.5rem !important;
}

.body .responsive-lg .mt-lg-n4, .body .responsive-lg .my-lg-n4 {
  margin-top: -1.5rem !important;
}

.body .responsive-lg .mr-lg-n4, .body .responsive-lg .mx-lg-n4 {
  margin-right: -1.5rem !important;
}

.body .responsive-lg .mb-lg-n4, .body .responsive-lg .my-lg-n4 {
  margin-bottom: -1.5rem !important;
}

.body .responsive-lg .ml-lg-n4, .body .responsive-lg .mx-lg-n4 {
  margin-left: -1.5rem !important;
}

.body .responsive-lg .m-lg-n5 {
  margin: -3rem !important;
}

.body .responsive-lg .mt-lg-n5, .body .responsive-lg .my-lg-n5 {
  margin-top: -3rem !important;
}

.body .responsive-lg .mr-lg-n5, .body .responsive-lg .mx-lg-n5 {
  margin-right: -3rem !important;
}

.body .responsive-lg .mb-lg-n5, .body .responsive-lg .my-lg-n5 {
  margin-bottom: -3rem !important;
}

.body .responsive-lg .ml-lg-n5, .body .responsive-lg .mx-lg-n5 {
  margin-left: -3rem !important;
}

.body .responsive-lg .m-lg-auto {
  margin: auto !important;
}

.body .responsive-lg .mt-lg-auto, .body .responsive-lg .my-lg-auto {
  margin-top: auto !important;
}

.body .responsive-lg .mr-lg-auto, .body .responsive-lg .mx-lg-auto {
  margin-right: auto !important;
}

.body .responsive-lg .mb-lg-auto, .body .responsive-lg .my-lg-auto {
  margin-bottom: auto !important;
}

.body .responsive-lg .ml-lg-auto, .body .responsive-lg .mx-lg-auto {
  margin-left: auto !important;
}

.body .responsive-xl .m-xl-0 {
  margin: 0 !important;
}

.body .responsive-xl .mt-xl-0, .body .responsive-xl .my-xl-0 {
  margin-top: 0 !important;
}

.body .responsive-xl .mr-xl-0, .body .responsive-xl .mx-xl-0 {
  margin-right: 0 !important;
}

.body .responsive-xl .mb-xl-0, .body .responsive-xl .my-xl-0 {
  margin-bottom: 0 !important;
}

.body .responsive-xl .ml-xl-0, .body .responsive-xl .mx-xl-0 {
  margin-left: 0 !important;
}

.body .responsive-xl .m-xl-1 {
  margin: .25rem !important;
}

.body .responsive-xl .mt-xl-1, .body .responsive-xl .my-xl-1 {
  margin-top: .25rem !important;
}

.body .responsive-xl .mr-xl-1, .body .responsive-xl .mx-xl-1 {
  margin-right: .25rem !important;
}

.body .responsive-xl .mb-xl-1, .body .responsive-xl .my-xl-1 {
  margin-bottom: .25rem !important;
}

.body .responsive-xl .ml-xl-1, .body .responsive-xl .mx-xl-1 {
  margin-left: .25rem !important;
}

.body .responsive-xl .m-xl-2 {
  margin: .5rem !important;
}

.body .responsive-xl .mt-xl-2, .body .responsive-xl .my-xl-2 {
  margin-top: .5rem !important;
}

.body .responsive-xl .mr-xl-2, .body .responsive-xl .mx-xl-2 {
  margin-right: .5rem !important;
}

.body .responsive-xl .mb-xl-2, .body .responsive-xl .my-xl-2 {
  margin-bottom: .5rem !important;
}

.body .responsive-xl .ml-xl-2, .body .responsive-xl .mx-xl-2 {
  margin-left: .5rem !important;
}

.body .responsive-xl .m-xl-3 {
  margin: 1rem !important;
}

.body .responsive-xl .mt-xl-3, .body .responsive-xl .my-xl-3 {
  margin-top: 1rem !important;
}

.body .responsive-xl .mr-xl-3, .body .responsive-xl .mx-xl-3 {
  margin-right: 1rem !important;
}

.body .responsive-xl .mb-xl-3, .body .responsive-xl .my-xl-3 {
  margin-bottom: 1rem !important;
}

.body .responsive-xl .ml-xl-3, .body .responsive-xl .mx-xl-3 {
  margin-left: 1rem !important;
}

.body .responsive-xl .m-xl-4 {
  margin: 1.5rem !important;
}

.body .responsive-xl .mt-xl-4, .body .responsive-xl .my-xl-4 {
  margin-top: 1.5rem !important;
}

.body .responsive-xl .mr-xl-4, .body .responsive-xl .mx-xl-4 {
  margin-right: 1.5rem !important;
}

.body .responsive-xl .mb-xl-4, .body .responsive-xl .my-xl-4 {
  margin-bottom: 1.5rem !important;
}

.body .responsive-xl .ml-xl-4, .body .responsive-xl .mx-xl-4 {
  margin-left: 1.5rem !important;
}

.body .responsive-xl .m-xl-5 {
  margin: 3rem !important;
}

.body .responsive-xl .mt-xl-5, .body .responsive-xl .my-xl-5 {
  margin-top: 3rem !important;
}

.body .responsive-xl .mr-xl-5, .body .responsive-xl .mx-xl-5 {
  margin-right: 3rem !important;
}

.body .responsive-xl .mb-xl-5, .body .responsive-xl .my-xl-5 {
  margin-bottom: 3rem !important;
}

.body .responsive-xl .ml-xl-5, .body .responsive-xl .mx-xl-5 {
  margin-left: 3rem !important;
}

.body .responsive-xl .p-xl-0 {
  padding: 0 !important;
}

.body .responsive-xl .pt-xl-0, .body .responsive-xl .py-xl-0 {
  padding-top: 0 !important;
}

.body .responsive-xl .pr-xl-0, .body .responsive-xl .px-xl-0 {
  padding-right: 0 !important;
}

.body .responsive-xl .pb-xl-0, .body .responsive-xl .py-xl-0 {
  padding-bottom: 0 !important;
}

.body .responsive-xl .pl-xl-0, .body .responsive-xl .px-xl-0 {
  padding-left: 0 !important;
}

.body .responsive-xl .p-xl-1 {
  padding: .25rem !important;
}

.body .responsive-xl .pt-xl-1, .body .responsive-xl .py-xl-1 {
  padding-top: .25rem !important;
}

.body .responsive-xl .pr-xl-1, .body .responsive-xl .px-xl-1 {
  padding-right: .25rem !important;
}

.body .responsive-xl .pb-xl-1, .body .responsive-xl .py-xl-1 {
  padding-bottom: .25rem !important;
}

.body .responsive-xl .pl-xl-1, .body .responsive-xl .px-xl-1 {
  padding-left: .25rem !important;
}

.body .responsive-xl .p-xl-2 {
  padding: .5rem !important;
}

.body .responsive-xl .pt-xl-2, .body .responsive-xl .py-xl-2 {
  padding-top: .5rem !important;
}

.body .responsive-xl .pr-xl-2, .body .responsive-xl .px-xl-2 {
  padding-right: .5rem !important;
}

.body .responsive-xl .pb-xl-2, .body .responsive-xl .py-xl-2 {
  padding-bottom: .5rem !important;
}

.body .responsive-xl .pl-xl-2, .body .responsive-xl .px-xl-2 {
  padding-left: .5rem !important;
}

.body .responsive-xl .p-xl-3 {
  padding: 1rem !important;
}

.body .responsive-xl .pt-xl-3, .body .responsive-xl .py-xl-3 {
  padding-top: 1rem !important;
}

.body .responsive-xl .pr-xl-3, .body .responsive-xl .px-xl-3 {
  padding-right: 1rem !important;
}

.body .responsive-xl .pb-xl-3, .body .responsive-xl .py-xl-3 {
  padding-bottom: 1rem !important;
}

.body .responsive-xl .pl-xl-3, .body .responsive-xl .px-xl-3 {
  padding-left: 1rem !important;
}

.body .responsive-xl .p-xl-4 {
  padding: 1.5rem !important;
}

.body .responsive-xl .pt-xl-4, .body .responsive-xl .py-xl-4 {
  padding-top: 1.5rem !important;
}

.body .responsive-xl .pr-xl-4, .body .responsive-xl .px-xl-4 {
  padding-right: 1.5rem !important;
}

.body .responsive-xl .pb-xl-4, .body .responsive-xl .py-xl-4 {
  padding-bottom: 1.5rem !important;
}

.body .responsive-xl .pl-xl-4, .body .responsive-xl .px-xl-4 {
  padding-left: 1.5rem !important;
}

.body .responsive-xl .p-xl-5 {
  padding: 3rem !important;
}

.body .responsive-xl .pt-xl-5, .body .responsive-xl .py-xl-5 {
  padding-top: 3rem !important;
}

.body .responsive-xl .pr-xl-5, .body .responsive-xl .px-xl-5 {
  padding-right: 3rem !important;
}

.body .responsive-xl .pb-xl-5, .body .responsive-xl .py-xl-5 {
  padding-bottom: 3rem !important;
}

.body .responsive-xl .pl-xl-5, .body .responsive-xl .px-xl-5 {
  padding-left: 3rem !important;
}

.body .responsive-xl .m-xl-n1 {
  margin: -.25rem !important;
}

.body .responsive-xl .mt-xl-n1, .body .responsive-xl .my-xl-n1 {
  margin-top: -.25rem !important;
}

.body .responsive-xl .mr-xl-n1, .body .responsive-xl .mx-xl-n1 {
  margin-right: -.25rem !important;
}

.body .responsive-xl .mb-xl-n1, .body .responsive-xl .my-xl-n1 {
  margin-bottom: -.25rem !important;
}

.body .responsive-xl .ml-xl-n1, .body .responsive-xl .mx-xl-n1 {
  margin-left: -.25rem !important;
}

.body .responsive-xl .m-xl-n2 {
  margin: -.5rem !important;
}

.body .responsive-xl .mt-xl-n2, .body .responsive-xl .my-xl-n2 {
  margin-top: -.5rem !important;
}

.body .responsive-xl .mr-xl-n2, .body .responsive-xl .mx-xl-n2 {
  margin-right: -.5rem !important;
}

.body .responsive-xl .mb-xl-n2, .body .responsive-xl .my-xl-n2 {
  margin-bottom: -.5rem !important;
}

.body .responsive-xl .ml-xl-n2, .body .responsive-xl .mx-xl-n2 {
  margin-left: -.5rem !important;
}

.body .responsive-xl .m-xl-n3 {
  margin: -1rem !important;
}

.body .responsive-xl .mt-xl-n3, .body .responsive-xl .my-xl-n3 {
  margin-top: -1rem !important;
}

.body .responsive-xl .mr-xl-n3, .body .responsive-xl .mx-xl-n3 {
  margin-right: -1rem !important;
}

.body .responsive-xl .mb-xl-n3, .body .responsive-xl .my-xl-n3 {
  margin-bottom: -1rem !important;
}

.body .responsive-xl .ml-xl-n3, .body .responsive-xl .mx-xl-n3 {
  margin-left: -1rem !important;
}

.body .responsive-xl .m-xl-n4 {
  margin: -1.5rem !important;
}

.body .responsive-xl .mt-xl-n4, .body .responsive-xl .my-xl-n4 {
  margin-top: -1.5rem !important;
}

.body .responsive-xl .mr-xl-n4, .body .responsive-xl .mx-xl-n4 {
  margin-right: -1.5rem !important;
}

.body .responsive-xl .mb-xl-n4, .body .responsive-xl .my-xl-n4 {
  margin-bottom: -1.5rem !important;
}

.body .responsive-xl .ml-xl-n4, .body .responsive-xl .mx-xl-n4 {
  margin-left: -1.5rem !important;
}

.body .responsive-xl .m-xl-n5 {
  margin: -3rem !important;
}

.body .responsive-xl .mt-xl-n5, .body .responsive-xl .my-xl-n5 {
  margin-top: -3rem !important;
}

.body .responsive-xl .mr-xl-n5, .body .responsive-xl .mx-xl-n5 {
  margin-right: -3rem !important;
}

.body .responsive-xl .mb-xl-n5, .body .responsive-xl .my-xl-n5 {
  margin-bottom: -3rem !important;
}

.body .responsive-xl .ml-xl-n5, .body .responsive-xl .mx-xl-n5 {
  margin-left: -3rem !important;
}

.body .responsive-xl .m-xl-auto {
  margin: auto !important;
}

.body .responsive-xl .mt-xl-auto, .body .responsive-xl .my-xl-auto {
  margin-top: auto !important;
}

.body .responsive-xl .mr-xl-auto, .body .responsive-xl .mx-xl-auto {
  margin-right: auto !important;
}

.body .responsive-xl .mb-xl-auto, .body .responsive-xl .my-xl-auto {
  margin-bottom: auto !important;
}

.body .responsive-xl .ml-xl-auto, .body .responsive-xl .mx-xl-auto {
  margin-left: auto !important;
}

.body .stretched-link:after {
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: #0000;
  position: absolute;
  inset: 0;
}

.body .text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace !important;
}

.body .text-justify {
  text-align: justify !important;
}

.body .text-wrap {
  white-space: normal !important;
}

.body .text-nowrap {
  white-space: nowrap !important;
}

.body .text-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.body .text-left {
  text-align: left !important;
}

.body .text-right {
  text-align: right !important;
}

.body .text-center {
  text-align: center !important;
}

.body .responsive-sm .text-sm-left {
  text-align: left !important;
}

.body .responsive-sm .text-sm-right {
  text-align: right !important;
}

.body .responsive-sm .text-sm-center {
  text-align: center !important;
}

.body .responsive-md .text-md-left {
  text-align: left !important;
}

.body .responsive-md .text-md-right {
  text-align: right !important;
}

.body .responsive-md .text-md-center {
  text-align: center !important;
}

.body .responsive-lg .text-lg-left {
  text-align: left !important;
}

.body .responsive-lg .text-lg-right {
  text-align: right !important;
}

.body .responsive-lg .text-lg-center {
  text-align: center !important;
}

.body .responsive-xl .text-xl-left {
  text-align: left !important;
}

.body .responsive-xl .text-xl-right {
  text-align: right !important;
}

.body .responsive-xl .text-xl-center {
  text-align: center !important;
}

.body .text-lowercase {
  text-transform: lowercase !important;
}

.body .text-uppercase {
  text-transform: uppercase !important;
}

.body .text-capitalize {
  text-transform: capitalize !important;
}

.body .font-weight-light {
  font-weight: 300 !important;
}

.body .font-weight-lighter {
  font-weight: lighter !important;
}

.body .font-weight-normal {
  font-weight: 400 !important;
}

.body .font-weight-bold {
  font-weight: 700 !important;
}

.body .font-weight-bolder {
  font-weight: bolder !important;
}

.body .font-italic {
  font-style: italic !important;
}

.body .text-white {
  color: #fff !important;
}

.body .text-primary {
  color: #007bff !important;
}

.body a.text-primary:hover, .body a.text-primary:focus {
  color: #0056b3 !important;
}

.body .text-secondary {
  color: #6c757d !important;
}

.body a.text-secondary:hover, .body a.text-secondary:focus {
  color: #494f54 !important;
}

.body .text-success {
  color: #28a745 !important;
}

.body a.text-success:hover, .body a.text-success:focus {
  color: #19692c !important;
}

.body .text-info {
  color: #17a2b8 !important;
}

.body a.text-info:hover, .body a.text-info:focus {
  color: #0f6674 !important;
}

.body .text-warning {
  color: #ffc107 !important;
}

.body a.text-warning:hover, .body a.text-warning:focus {
  color: #ba8b00 !important;
}

.body .text-danger {
  color: #dc3545 !important;
}

.body a.text-danger:hover, .body a.text-danger:focus {
  color: #a71d2a !important;
}

.body .text-light {
  color: #f8f9fa !important;
}

.body a.text-light:hover, .body a.text-light:focus {
  color: #cbd3da !important;
}

.body .text-dark {
  color: #343a40 !important;
}

.body a.text-dark:hover, .body a.text-dark:focus {
  color: #121416 !important;
}

.body .text-body {
  color: #212529 !important;
}

.body .text-muted {
  color: #6c757d !important;
}

.body .text-black-50 {
  color: #00000080 !important;
}

.body .text-white-50 {
  color: #ffffff80 !important;
}

.body .text-hide {
  font: 0 / 0 a;
  color: #0000;
  text-shadow: none;
  background-color: #0000;
  border: 0;
}

.body .text-decoration-none {
  text-decoration: none !important;
}

.body .text-break {
  word-break: break-word !important;
  word-wrap: break-word !important;
}

.body .text-reset {
  color: inherit !important;
}

.body .visible {
  visibility: visible !important;
}

.body .invisible {
  visibility: hidden !important;
}

.body * {
  border-width: 0;
  border-style: solid !important;
}

.body a {
  text-decoration: none !important;
}

.body header {
  width: 100%;
  z-index: 10;
  flex-flow: row;
  justify-content: space-between;
  display: flex;
  position: fixed;
}

.body header .container {
  z-index: 10;
  padding: 0;
}

.body header .navbar .navbar-toggler {
  cursor: pointer;
  font-size: 1.25rem;
  right: 0;
}

.body header .navbar {
  height: 80px;
  z-index: 10;
}

.body header img, .body header video {
  max-height: 60px;
  top: 0;
  left: 0;
  transform: none;
}

.body footer {
  width: 100%;
  scroll-snap-align: center;
  position: fixed;
  bottom: 0;
}

.body .header *, .body .footer * {
  background: none !important;
  border: none !important;
  transform: none !important;
}

.body .header .navbar-nav .nav-link, .body .footer .navbar-nav .nav-link {
  padding-left: 1rem;
  padding-right: 1rem;
}

.body .header h4, .body .footer h4 {
  margin-left: 40px;
  text-align: left !important;
}

.body .header a, .body .footer a {
  cursor: pointer;
  opacity: .8;
  background: none;
  transform: none;
  pointer-events: visible !important;
}

.body .header a:hover, .body .footer a:hover {
  opacity: 1;
}

.body .header a {
  margin-bottom: 10px;
}

.body .header svg {
  position: relative;
  top: 15px;
  left: 20px;
}

.body .footer #about-button {
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  font-size: 20px;
  display: flex;
}

.body .footer #f-socials {
  justify-content: end;
}

.body .footer a {
  font-size: 25px;
}

.body #socials, .body #f-socials {
  height: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
}

.body #socials a, .body #f-socials a {
  font-size: 25px;
  text-decoration: none !important;
}

.browser {
  height: 100%;
  width: 100%;
  max-height: 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding: inherit;
  position: absolute;
  top: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  z-index: 1 !important;
}

.browser img, .browser video {
  width: auto;
  max-width: 100%;
  max-height: 60%;
  -o-object-fit: contain;
  object-fit: contain;
  display: flex;
  position: relative;
}

.browser a {
  padding: 10px;
}

.browser .sections {
  opacity: 0;
}

.browser section {
  scroll-snap-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

.browser section .row {
  margin: 0;
}

.browser section .row div[class^="col"] {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.browser section h1, .browser section p {
  margin: 0 !important;
}

@media (max-width: 576px) {
  .body .footer #f-socials {
    justify-content: center;
  }

  .body .footer #about-button {
    align-items: center;
  }

  #website * {
    transform: none !important;
  }
}

#watermark-container {
  justify-content: center;
  align-items: center;
  display: flex;
}

#watermark-container .watermark {
  width: 180px;
  cursor: pointer;
  background: #000;
  border-radius: 3px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  margin: 0;
  padding: 8px;
  display: flex;
}

#watermark-container .watermark .watermark-text {
  color: #fff;
  text-align: right;
  text-align: center;
  margin-bottom: 0;
  font-family: Arial;
  font-size: 14px;
}

#watermark-container .watermark .watermark-logo {
  width: 24px;
  height: 24px;
  border-radius: 3px;
}

/*# sourceMappingURL=engine.css.map */
