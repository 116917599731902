.center
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)

.centerX
    left: 50%
    transform: translate(-50%, 0%)

.fullscreen
    position: absolute
    height: 100%
    width: 100%

canvas
    position: fixed
    top: 0px
    left: 0px
    max-width: 100%
    height: 100%
    width: 100%
    outline: none

.body
    box-sizing: border-box
    overflow: hidden
    margin: 0px
    // Otherwise it uses spectrum value
    line-height: normal
    font-style: normal
    font-weight: 400

    @import './bootstrap'

    $headerHeight: 80px

    *
        border-width: 0px
        border-style: solid !important

    a
        text-decoration: none !important

    header
        width: 100%
        position: fixed
        display: flex /* establish flex container */
        flex-direction: row /* default value can be omitted */
        flex-wrap: nowrap /* default value can be omitted */
        justify-content: space-between /* switched from default (flex-start, see below) */
        z-index: 10

        .container
            padding: 0px
            z-index: 10

        .navbar

            .navbar-toggler
                right: 0px
                cursor: pointer
                font-size: 1.25rem

        .navbar
            height: $headerHeight
            z-index: 10

        img, video
            max-height: $headerHeight - 20px
            top: 0px
            left: 0px
            transform: none

    footer
        position: fixed
        width: 100%
        bottom: 0px
        scroll-snap-align: center

    .header, .footer

        *
            transform: none !important
            border: none !important
            background: none !important

        .navbar-nav .nav-link
            padding-left: 1rem
            padding-right: 1rem

        h4
            text-align: left !important
            margin-left: 40px

        a
            background: rgba(0,0,0,0)
            cursor: pointer
            opacity: 0.8
            transform: none
            pointer-events: visible !important

            &:hover
                opacity: 1

    .header
        a
            margin-bottom: 10px

        svg
            position: relative
            top: 15px
            left: 20px

    .footer
        #about-button
            display: flex
            font-size: 20px
            justify-content: center
            flex-direction: column
            height: 100%
            align-items: start

        #f-socials
            justify-content: end

        a
            font-size: 25px

    #socials, #f-socials
        height: 100%
        display: flex
        flex-direction: row
        align-items: center
        justify-content: center

        a
            font-size: 25px
            text-decoration: none !important

.browser
    position: absolute
    top: 0
    left: 0
    overflow: hidden auto
    z-index: 1 !important
    height: 100%
    width: 100%
    max-height: 100%
    max-width: 100%
    box-sizing: border-box
    padding: inherit

    img, video
        position: relative
        width: auto
        max-width: 100%
        max-height: 60%
        object-fit: contain
        display: flex

    a
        padding: 10px

    .sections
        opacity: 0

    section
        scroll-snap-align: center
        display: flex
        align-items: center
        justify-content: center

        //! margin make text overlap due to other changes
        .row
            margin: 0px

            div[class^="col"]
                align-items: center
                justify-content: center
                gap: 10px
                display: flex
                flex-direction: column

        h1, p
            margin: 0 !important
            margin-bottom: 0 !important

@media (max-width: 576px)
    .body
        .footer
            #f-socials
                justify-content: center

            #about-button
                align-items: center

    #website
        *
            transform: none !important

#watermark-container
    display: flex
    justify-content: center
    align-items: center

    .watermark
        width: 180px
        padding: 8px
        margin: 0px
        background: black
        border-radius: 3px
        cursor: pointer
        display: flex
        flex-direction: row
        justify-content: center
        align-items: center
        gap: 5px

        .watermark-text
            color: white
            font-size: 14px
            text-align: right
            font-family: Arial
            margin-bottom: 0px
            text-align: center

        .watermark-logo
            width: 24px
            height: 24px
            border-radius: 3px
