.html-container
    position: fixed
    top: 80px
    left: 0px
    width: 100%
    height: calc(100% - 80px)
    opacity: 0
    pointer-events: none
    transition: opacity ease 0.2s
    z-index: 100000000
    overflow: auto
    padding-bottom: 100px

    .menu
        height: 100%
        display: flex
        flex-direction: column

    #medias
        align-items: center
        display: flex
        flex-direction: column
        gap: 10px

    .navbar-nav
        position: absolute

    section
        margin-top: 0px !important
        margin-bottom: 0px !important
        min-height: 100% !important

    .container
        height: 100%

    .h1
        margin-bottom: 20px !important

    #summary-container, #skills-container, #softwares-container, #location-container, #contact-container
        display: none

    #location-container, #contact-container
        flex-direction: row
        gap: 10px
        align-items: baseline

    #picture
        max-width: 300px

    #skills
        display: flex
        flex-wrap: wrap
        gap: 10px

    .skill
        font-size: 15px
        border: 1px
        padding: 5px
        padding-left: 10px
        padding-right: 10px
        float: left
        text-transform: uppercase

    hr
        margin-top: 2rem
        margin-bottom: 2rem
        border-top: 1px solid grey

    div[class^="col"]
        justify-content: start !important

.show
    opacity: 1
    pointer-events: visible
    transition: opacity ease 1s
